import { Component } from 'react'
import server from 'utils/api/server'
import Cookies from 'universal-cookie'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { getDevchXVIIData } from '../../actions/user_actions'
import DropdownList from '../form_elements/DropDownList'
import TextField from '../form_elements/TextField'
import Boolean from '../form_elements/Boolean'


class DevcXVIIForm extends Component {
  constructor (props) {
    super(props)
    this.next_page = this.next_page.bind(this)
    this.submit = this.submit.bind(this)
    this.cookies = new Cookies()
    this.state = {
      before_submit: true,
      text_button  : 'Надіслати',
      collapsed    : false,
      logged_in    : this.cookies.get('Token') ? true : !!(false || this.cookies.get('TokenFb')),
      check_one    : false,
      check_two    : false,
      checkbox_ONE : true,
      form_info    : '',
      number_error : false,
      devops       : false,
    }
  }

  componentDidMount () {
    if (this.state.logged_in) {
      this.props.dispatch(getDevchXVIIData())
    } else {
      this.props.history.push('login')
    }
  }

  next_page () {
    const error = false

    if (!error) {
      this.setState({ number_error: false })
      this.setState({
        collapsed: !this.state.collapsed,
      })
    }
  }

  submit () {
    this.setState({
      text_button  : 'Іде надсилання',
      before_submit: false,
    })
    const body = {
      nomination      : this.state.nomination,
      professional_lvl: this.state.professional_lvl,
      personal_website: this.state.personal_website,
      portfolio       : this.state.portfolio,
      product_design  : this.state.product_design,
      devops          : this.state.devops,
      backend         : this.state.backend,
      app_design      : this.state.app_design,
      frontend_code   : this.state.frontend_code,
      frontend_layout : this.state.frontend_layout,
      qa_qs           : this.state.qa_qs,
    }

    server.post('/api/posts/form/devch_xvii', body).then((response) => {
      if (response.status === 200) {
        this.props.history.push('/auth')
        window.location.reload()
      }
    })
      .catch((_) => {})
  }

    Callback_nomination = (data) => {
      this.setState({ nomination: data })
    };

    Callback_portfolio = (data) => {
      this.setState({ portfolio: data })
    };

    Callback_personal_website = (data) => {
      this.setState({ personal_website: data })
    };

    Callback_proffesional_lvl = (data) => {
      this.setState({ professional_lvl: data })
    };

    Callback_boolean = (data) => {
      this.setState(data)
    };

    render () {
      return (
        <div
          className='FormFidgetWrapper'
          style={{ counterReset: 'number' }}
        >
          <div className='imageFullFormBack'>
            <div className='imageFullFormBackInner'>
              <div className='imageFullFormBackHeader'>
                <h1>/ Вибір номінації DEV Challenge XVIII</h1>
              </div>
              <div className='imageFullFormBackContent'>
                <p>Обери свій челендж на цей сезон</p>
              </div>
            </div>
          </div>
          <div className='wrapper_inner_form'>
            <div className='animationFatherDouble'>
              <div
                className='upw_content upw_content_first_page'
                style={this.state.collapsed
                              ? {
                                  right: '100%',
                                }
                              : {
                                  left   : '0',
                                  display: (this.state.collapsed ? 'none' : 'block'),
                }}
              >
                {this.props.fetched
                              ? (
                                <div className='upw_inner_content'>
                                  <div className='upw_form_title'>
                                    Чотири поля і, як кажуть, #devchallengeaccepted
                                  </div>
                                  {this.props.fetched_form
                                      ? (
                                        <div className='upw_form_field'>
                                          <DropdownList
                                            prefill={{ text: this.props.form_info.professional_lvl }}
                                            ParentCallback={this.Callback_proffesional_lvl}
                                            info={{
                                                  Title: 'Професійний рівень:',
                                                  Items: ['Junior', 'Middle', 'Senior'],
                                            }}
                                          />
                                        </div>
)
                                      : null}
                                  {this.props.fetched_form
                                      ? (
                                        <div className='upw_form_field'>
                                          <TextField
                                            ParentCallback={this.Callback_portfolio}
                                            prefill={{ text: this.props.form_info.portfolio }}
                                            info={{ Title: 'Посилання на портфоліо' }}
                                          />
                                        </div>
)
                                      : null}
                                  {this.props.fetched_form
                                      ? (
                                        <div className='upw_form_field'>
                                          <TextField

                                            ParentCallback={this.Callback_personal_website}
                                            prefill={{ text: this.props.form_info.personal_website }}
                                            info={{ Title: 'Персональний сайт' }}
                                          />
                                        </div>
)
                                      : null}
                                  {this.props.fetched_form
                                      ? (
                                        <>
                                          <Boolean callback={this.Callback_boolean} default_value={this.props.form_info.product_design} key_name='product_design' label={'Взяти участь у Product Design (UX)'} />
                                          <Boolean callback={this.Callback_boolean} default_value={this.props.form_info.app_design} key_name='app_design' label={'Взяти участь у App Design (UI)'} />
                                          <Boolean callback={this.Callback_boolean} default_value={this.props.form_info.backend} key_name='backend' label={'Взяти участь у Backend'} />
                                          <Boolean callback={this.Callback_boolean} default_value={this.props.form_info.frontend_layout} key_name='frontend_layout' label={'Взяти участь у Frontend Layout'} />
                                          <Boolean callback={this.Callback_boolean} default_value={this.props.form_info.frontend_code} key_name='frontend_code' label={'Взяти участь у Frontend Code'} />
                                          <Boolean callback={this.Callback_boolean} default_value={this.props.form_info.qa_qs} key_name='qa_qs' label={'Взяти участь у Manual QA'} />
                                          <Boolean callback={this.Callback_boolean} default_value={this.props.form_info.devops} key_name='devops' label={'Взяти участь у DevOps '} />
                                        </>
)
                                      : null}
                                  <div className='upw_form_field'>
                                    <div
                                      className={'upw_button upw_button_form '}
                                      onClick={this.submit}
                                    >
                                      {this.state.text_button}
                                    </div>
                                  </div>
                                </div>
)
                              : null}
              </div>
            </div>
          </div>
        </div>
      )
    }
}

function mapStateToProps (state) {
  return {
    user        : state.user,
    user_info   : state.user_info,
    fetched     : state.fetched,
    fetched_form: state.fetched_form,
    form_info   : state.form_info,
  }
}

export default connect(mapStateToProps)(withRouter(DevcXVIIForm))
