import server from 'utils/api/server'
import Cookies from 'universal-cookie'


const cookies = new Cookies()


export function getData () {
  return function (dispatch) {
    dispatch({ type: 'FETCH_USERS_START' })
    server.get('/api/posts/token/data').then(
      (res) => {
        dispatch({
          type   : 'RECEIVE_USERS_INFO',
          payload: res.data,
        })
      })
      .catch(function (err) {
        dispatch({
          type   : 'FETCH_USERS_ERROR',
          payload: err,
        })
      })
  }
}

export function getFormData () {
  return function (dispatch) {
    dispatch({ type: 'FETCH_USERS_START' })
    server.get('/api/posts/token/form').then(
      (res) => {
        dispatch({
          type   : 'RECEIVE_FORM_INFO',
          payload: res.data,
        })

        return 'Done'
      })
      .catch(function (_) {
        dispatch({ type: 'FETCH_FORM_ERROR', payload: 'Err' })
      })

    return 'Done'
  }
}

export function getBackendData () {
  return function (dispatch) {
    dispatch({ type: 'FETCH_USERS_START' })
    server.get('/api/posts/token/backend-form').then(
      (res) => {
        dispatch({
          type   : 'RECEIVE_FORM_INFO',
          payload: res.data,
        })

        return 'Done'
      })
      .catch(function (_) {
        dispatch({ type: 'FETCH_FORM_ERROR', payload: 'Err' })
      })

    return 'Done'
  }
}

export function getDesignData () {
  return function (dispatch) {
    dispatch({ type: 'FETCH_USERS_START' })
    server.get('/api/posts/form/design_form_list').then(
      (res) => {
        dispatch({
          type   : 'RECEIVE_FORM_INFO',
          payload: res.data,
        })

        return 'Done'
      })
      .catch(function (_) {
        dispatch({ type: 'FETCH_FORM_ERROR', payload: 'Err' })
      })

    return 'Done'
  }
}

export function getTeamFormXVII () {
  return function (dispatch) {
    dispatch({ type: 'FETCH_USERS_START' })
    server.get('/api/posts/form/devch_xvii_team_form').then(
      (res) => {
        dispatch({
          type   : 'RECEIVE_FORM_INFO',
          payload: res.data,
        })

        return 'Done'
      })
      .catch(function (_) {
        dispatch({ type: 'FETCH_FORM_ERROR', payload: 'Err' })
      })

    return 'Done'
  }
}

export function getDevchXVIIData () {
  return function (dispatch) {
    dispatch({ type: 'FETCH_USERS_START' })
    server.get('/api/posts/form/devch_xvii_list').then(
      (res) => {
        dispatch({
          type   : 'RECEIVE_FORM_INFO',
          payload: res.data,
        })

        return 'Done'
      })
      .catch(function (_) {
        dispatch({ type: 'FETCH_FORM_ERROR', payload: 'Err' })
      })

    return 'Done'
  }
}


export function getFrontendData () {
  return function (dispatch) {
    dispatch({ type: 'FETCH_USERS_START' })
    server.get('/api/posts/token/frontend-form').then(
      (res) => {
        dispatch({
          type   : 'RECEIVE_FORM_INFO',
          payload: res.data,
        })

        return 'Done'
      })
      .catch(function (_) {
        dispatch({ type: 'FETCH_FORM_ERROR', payload: 'Err' })
      })

    return 'Done'
  }
}

export function logIn (email, password, FB_ID = 0) {
  return function (dispatch) {
    dispatch({ type: 'FETCH_USERS_START' })
    server.post('/api/posts/api-token-auth/', {
      email   : email,
      password: password,
      FB_ID   : FB_ID,
    }).then((response) => {
      if (response.status === 400) {
        dispatch({ type: 'FETCH_USERS_ERROR', payload: true })
      }
      cookies.set('Token', response.data.token, { path: '/' })

      dispatch({ type: 'RECEIVE_USERS', payload: response.data })
    })
      .catch((_) => {
        dispatch({ type: 'LOGIN_ERROR', payload: 'err' })
      })
  }
}
