import { Component } from 'react'
import Cookies from 'universal-cookie'
import server from 'utils/api/server'
import jwt from 'jsonwebtoken'
import { withRouter } from 'react-router-dom'

import { mediaURL } from 'global.js'

import { ReactComponent as RocketIcon } from 'assets/rocket.svg'


class ConnectionWidget extends Component {
  constructor (props) {
    super(props)

    this.isLogged = this.isLogged.bind(this)
    this.get_data_about_user = this.get_data_about_user.bind(this)
    this.cookies = new Cookies()
    this.submit = this.submit.bind(this)
    this.setMail = this.setMail.bind(this)
    this.state = {
      redirect: '',
      response: false,
      mail    : undefined,
      name    : undefined,
      message : undefined,

      upw_is_loading: false,
    }
  }

  submit (e) {
    this.setState({ upw_is_loading: true })
    server.post('/api/posts/connection/send', {
      message: this.state.message,
      email  : this.state.mail,
      name   : this.state.name,
    }).then((response) => {
      if (response.status === 200) this.setState({ response: true })
    })
      .catch((_) => {
        this.setState({ upw_is_loading: false })
      })
  }

  isLogged () {
    if (this.props.user_data !== null && this.props.user_data !== '' && this.props.user_data !== undefined) {
      return true
    }

    return false
  }

  get_data_about_user () {
    const date = this.cookies.get('Token')

    return jwt.decode(date).username
  }

  setMail (e) {
    const email = e.target.value

    if (email.length === 0 || email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) === null) {
      this.setState({ email_error: true })
    } else {
      this.setState({ email_error: false, mail: email })
    }
  }

  render () {
    return (
      <div style={{ width: '100%' }}>

        <div id='authorization_form'>
          {this.props.item.text_for_not_image

                      ? (
                        <div className='connectionData'>
                          <div
                            dangerouslySetInnerHTML={{ __html: this.props.item.text_for_not_image }}
                            className='innerConnection'
                          />
                        </div>
)
                      : (
                        <div className='authorization_image_block'>
                          <div
                            className='inner_image_auth'
                            style={{ backgroundImage: `url('${mediaURL}/media/local/1101_connection_team.png')` }}
                          >

                          </div>
                          <div className='media_text_block_inner'>

                            <span
                              className='data_inner_media_auth'
                              style={{
                                             float   : 'left',
                                             position: 'relative',
                              }}
                            >
                              <span
                                className='slesh'
                                style={{
                                             position: 'absolute',
                                             left    : '-30px',
                                             top     : '0',
                                }}
                              >
                                /
                              </span>
                              Зворотній
                              <br />
                              зв&apos;язок

                            </span>

                          </div>

                        </div>
)}
          <div className='form_block'>
            <div className='form_section_body'>

              <div
                className='upw_inner_content' style={{
                              textAlign: 'center',
                              display  : this.state.response ? 'block' : 'none',
                }}
              >
                <RocketIcon className='upw_rocket' />
                <p
                  className='upw_rocket_text'
                  style={{ fontFamily: 'InputMono' }}
                >
                  Вітаємо,
                  <br />
                  форма успішно відправлена!
                  <br />
                  <br />

                  Будемо намагатися відповісти максимально
                  швидко, ми розуміємо що питання важливе.
                  Графік роботи саппорту: 10:00 - 18:00.
                </p>

              </div>
              <form
                style={{ display: (this.state.response ? 'none' : '') }}
              >
                <div id='anketa_top_fixed'>
                  Є запитання? Запитай нас і ми з радістю відповімо:

                </div>
                {this.state.response
                                  ? <h2>Дякую За ваше повідовлення</h2>
                                  : (
                                    <div id='inputs_area'>
                                      {!this.isLogged()
                                          ? (
                                            <div
                                              className='auth_area_default'
                                            >
                                              <input
                                                ref='username'
                                                className='animation_input_auth'
                                                type='text'
                                                name='username'
                                                required
                                                onChange={this.setMail}
                                              />
                                              <label
                                                className='form__label'
                                              >
                                                E-mail:
                                              </label>
                                            </div>
)
                                          : null}
                                      {!this.isLogged()
                                          ? (
                                            <div
                                              className='auth_area_default'
                                            >
                                              <input
                                                ref='name'
                                                className='animation_input_auth'
                                                type='text'
                                                name='password'
                                                required
                                                onChange={(e) => this.setState({ name: e.target.value })}
                                              />
                                              <label
                                                className='form__label'
                                              >
                                                Ім&apos;я:
                                              </label>
                                            </div>
)
                                          : null}
                                      <div
                                        className='auth_area_default'
                                      >
                                        <input
                                          ref='message'
                                          className='animation_input_auth'
                                          type='text'
                                          name='password'
                                          required
                                          onChange={(e) => this.setState({ message: e.target.value })}
                                        />
                                        <label
                                          className='form__label'
                                        >
                                          Повідомлення:
                                        </label>
                                      </div>
                                      <button
                                        className={`upw_button upw_button_form${((this.state.mail && !this.state.email_error && this.state.name && this.state.message) || (this.isLogged() && this.state.message)) ? '' : ' upw_button_disabled'}${this.state.upw_is_loading ? ' upw_button_disabled' : ''}`}
                                        data-busy-label='Зачекайте будь ласка ...'
                                        onClick={

                                                this.submit
                                            }
                                      >
                                        {' '}
                                        {this.state.upw_is_loading ? 'Іде надсилання...' : 'Запитати'}
                                      </button>

                                    </div>
)}
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(ConnectionWidget)
