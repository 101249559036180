import { Component } from 'react'
import Cookies from 'universal-cookie'
import server from 'utils/api/server'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { logIn, getData } from 'actions/user_actions'

import { ReactComponent as QuestionIcon } from 'assets/question.svg'


class LoginWidget extends Component {
  constructor (props) {
    super(props)
    this.promises = []
    this.cookies = new Cookies()
    this.error_handled = false
    this.state = {
      redirect      : '',
      response      : '',
      check         : '',
      error         : false,
      isLoggedIn    : false,
      userID        : '',
      name          : '',
      email         : '',
      upw_name      : undefined,
      upw_pass      : undefined,
      upw_is_sending: false,
    }
  }

    button_out = (e) => {
      e.preventDefault()
      this.cookies.remove('Token', { path: '/' })
      delete server.defaults.headers.common.Authorization
      window.location.reload()
    };

    login_in = (e) => {
      e.preventDefault()
      this.props.logIn(this.refs.email.value, this.refs.password.value)
    };

    componentClicked () {
    }

    getFbToken = (email, FB_ID) => {
      const info = {
        email: email,
        FB_ID: FB_ID,
      }

      server.post('/api/posts/token/facebook', info).then(res => {
        this.cookies.set('Token', res.data.token, { path: '/' })
        this.props.history.push('/auth')
        window.location.reload()
      })
        .catch((_) => {

        })
    }

    responseFacebook = response => {
      const info = {
        username    : response.name,
        password    : '98238509822746090435944972894080312675384980714153',
        mobile_phone: 'Facebook User',
        FB_ID       : response.id,
        email       : response.email,
      }

      server.post('/api/posts/register', info).then(res => {
        this.getFbToken(response.email, response.id)
      })
        .catch((_) => {
          this.getFbToken(response.email, response.id)
        })
    };

    render () {
      return (

        <div style={{ width: '100%' }}>
          {this.props.logged_in
                  ? (() => {
                      this.props.history.push('/auth')
                      window.location.reload()
                    })()
                  : ''}
          <div id='authorization_form'>

            <div className='authorization_image_block'>
              <div className='inner_image_auth'>

              </div>
              <div className='media_text_block_inner'>

                <span
                  className='data_inner_media_auth'
                  style={{
                                        float   : 'left',
                                        position: 'relative',
                  }}
                >
                  <span
                    className='slesh'
                    style={{
                                        position: 'absolute',
                                        left    : '-30px',
                                        top     : '0',
                    }}
                  >
                    /
                  </span>
                  Увійдіть
                  <br />
                  на сайт,

                </span>
                <span
                  className='reason_inner'
                  style={{
                                    position: 'absolute',
                                    left    : '0',
                  }}
                >
                  щоб потрапити до Особистого кабінету
                </span>

              </div>

            </div>
            <div className='form_block'>
              <div className='form_section_body'>
                <form>
                  <div id='anketa_top_fixed'>
                    Щоб заповнити анкету учасника,
                    <br />
                    <a
                      href='registration'
                      className='link_register_dashed'
                    >
                      зареєструйтеся
                    </a>
                    {' '}
                    або
                    {' '}
                    <span
                      className='login_style_backgorund'
                    >
                      увійдіть
                    </span>

                  </div>
                  <div id='inputs_area'>
                    <div className='auth_area_default'>
                      <input
                        ref='email'
                        className='animation_input_auth'
                        type='text'
                        name='email'
                        required
                        onChange={(e) => this.setState({ upw_name: e.target.value })}
                      />
                      <label
                        className='form__label'
                      >
                        Email:
                      </label>
                      {this.props.login_error
                                          ? (
                                            <div
                                              className='errorField
                                            '
                                            >
                                              невірний email чи пароль
                                            </div>
)
                                          : null}
                    </div>
                    <div
                      className='auth_area_default'
                    >
                      <input
                        ref='password'
                        className='animation_input_auth'
                        type='password'
                        name='password'
                        required
                        onChange={(e) => this.setState({ upw_pass: e.target.value })}
                      />
                      <label
                        className='form__label'
                      >
                        Пароль:
                      </label>
                      {this.props.login_error
                                          ? (
                                            <div
                                              className='errorField
                                            '
                                            >
                                            </div>
)
                                          : null}
                      <div
                        className='upw_password_recovery'
                        onClick={
                                                 () => {
                                                   if (this.state.hint) {
                                                     this.setState({ hint: false })
                                                   } else {
                                                     this.setState({ hint: true })
                                                   }
                        }
                                             }
                      >
                        <div
                          className={`upw_recovery_logo${(this.state.hint) ? ' upw_clicked_recovery' : ''}`}
                        >
                          <QuestionIcon />
                        </div>

                      </div>
                      <div
                        className={`upw_hint${(this.state.hint) ? '' : '  upw_hint_hidden'}`}
                      >
                        Забули пароль? Не біда.
                        <br />
                        Ви можете відновити його за
                        допомогою
                        <br />
                        <a
                          className='upw_link_dotted'
                          href='https://api.prd.devchallenge.it/password_reset/'
                        >
                          форми
                          відновлення пароля
                        </a>
                      </div>
                    </div>
                    <button
                      className={`upw_button upw_button_form${(this.state.upw_name && this.state.upw_pass) ? '' : ' upw_button_disabled'}${this.props.fetching ? ' upw_button_disabled' : ''}`}
                      data-busy-label='Зачекайте будь ласка ...'
                      onClick={this.login_in}
                    >
                      {this.state.upw_is_sending ? 'Іде надсилання...' : 'Увійти'}
                    </button>

                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      )
    }
}

function mapStateToProps (state) {
  return {
    user       : state.user,
    fetched    : state.fetched,
    error      : state.error,
    login_error: state.login_error,
    user_info  : state.user_info,
    logged_in  : state.logged_in,
    fetching   : state.fetching,
  }
}

export default connect(mapStateToProps, {
  logIn,
  getData,
})(withRouter(LoginWidget))
