import { Component } from 'react'
import Cookies from 'universal-cookie'
import server from 'utils/api/server'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { getData } from 'actions/user_actions'
import { mediaURL } from 'global.js'
import { Menu } from 'components/layout/navigation'

import { ReactComponent as UserIcon } from 'assets/user.svg'
import { ReactComponent as CloseLargeIcon } from 'assets/close-large.svg'
import { ReactComponent as HamburgerIcon } from 'assets/hamburger.svg'


class NavBarWidget extends Component {
  constructor (props) {
    super(props)
    this.nav_items = ['DEV challenge', 'Challenges', 'партнери', 'судді', "зв'язок"]
    this.nav_href = ['/', '/challenges', '/partners', '/judges', '/feedback']
    // this.isLogged = this.isLogged.bind(this);
    this.username = ''
    this.button_out = this.button_out.bind(this)
    this.change_height = this.change_height.bind(this)
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this.cookies = new Cookies()
    this.state = {
      active   : 2,
      collapsed: false,
      width    : 0,
      username : '',
      logged_in: this.cookies.get('Token') ? true : !!(false || this.cookies.get('TokenFb')),
      height   : 0,
    }
  }

  componentWillMount () {
    this.setState({ logged_in: this.cookies.get('Token') ? true : !!(false || this.cookies.get('TokenFb')) })
  }

  componentDidMount () {
    this.setState({ logged_in: this.cookies.get('Token') ? true : !!(false || this.cookies.get('TokenFb')) })
    if (this.props.logged_in) this.props.dispatch(getData())
    this.updateWindowDimensions()

    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions () {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  change_height () {
    this.setState({ collapsed: !this.state.collapsed })
  }

  button_out (e) {
    e.preventDefault()
    this.setState({ logged: false })
    delete server.defaults.headers.common.Authorization
    this.cookies.remove('Token', { path: '/' })
    this.cookies.remove('TokenFb', { path: '/' })
    this.props.history.push('/')
    window.location.reload()
  }

  componentDidUpdate () {
    // this.setState({"logged_in": this.cookies.get('Token') ? true : false || this.cookies.get('TokenFb') ? true : false})
  }

  render () {
    return (

      <nav
        id='top-bar-fixed'
        style={this.state.collapsed ? { height: '560px' } : this.state.width > 1000 ? { height: '100px' } : { height: '50px' }}
      >
        <Menu
          style={{ // Until nav refactor
                  display: 'table',
                  float  : 'left',
                  padding: '0',
          }}
        />
        <div className='login_logout_field'>
          {this.props.user_inf !== '' && this.state.logged_in
                      ? (
                        <div style={{ marginTop: '8px' }}>
                          <a href='auth' id='user-prof-logo-name'>
                            <div id='user-displayed-auth'>
                              {this.props.user_inf.username}
                              {/* {this.props.user} */}
                            </div>
                            <div
                              id='svg-person-auth' style={{
                                  height: '42px',
                                  width : '42px',
                                  margin: 'auto',
                              }}
                            >
                              <UserIcon id='svg-icon-user' />
                            </div>
                          </a>
                          <Link to='/'>
                            <button
                              className='button_out_nav logout-anim'
                              onClick={this.button_out}
                            >
                              вийти
                            </button>
                          </Link>

                        </div>
)
                      : (
                        <div className='login_logout_inner'>
                          <a href='login'>
                            <button
                              id='login_button'
                              className='login-anim'
                            >
                              вхід
                            </button>
                          </a>
                          <span
                            className='link-registration'
                          >
                            <a
                              href='registration'
                              className='registrationSpan'
                            >
                              реєстрація
                            </a>
                          </span>
                        </div>
)}
        </div>
        <a href='/'>
          <img
            id='logo_dev'
            alt='devchallenge'
            src={`${mediaURL}/media/local/dev_challenge_XVII.png`}
            height='90px'
          />
          <img
            id='logo_small_nav_bar'
            alt='mini-logo-devch'
            src={`${mediaURL}/media/local/logo_small_XVII.png`}
          />
        </a>
        <div id='exit_but' onClick={this.change_height}>
          {this.state.collapsed
                      ? <CloseLargeIcon id='svg-icon-close-large' width='100%' height='100%' />
                      : <HamburgerIcon id='svg-icon-hamburger' width='100%' height='100%' />}
        </div>
      </nav>

    )
  }
}

function mapStateToProps (state) {
  return {
    user     : state.user,
    user_inf : state.user_info,
    fetched  : state.fetched,
    logged_in: state.logged_in,
  }
}

export default connect(mapStateToProps)(withRouter(NavBarWidget))
