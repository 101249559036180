import { Component } from 'react'


class SliderPhoto extends Component {
  collect_images () {
    const images = []

    this.props.item.block_data.forEach((obj) => {
      images.push(<img src={obj.image.file_image_svg} alt='' />)
    })

    return images
  }

  constructor (props) {
    super(props)
    this.images = this.collect_images()
    this.title = this.props.item.name
    this.collect_images = this.collect_images.bind(this)
    this.render = this.render.bind(this)
    this.switcher = this.switcher.bind(this)
    this.buttonGenerator = this.buttonGenerator.bind(this)
    this.listGenerator = this.listGenerator.bind(this)
    // Part of functions for swipe
    this._onMouseMove = this._onMouseMove.bind(this)
    this._onMouseUp = this._onMouseUp.bind(this)
    this._onMouseDown = this._onMouseDown.bind(this)
    this._onMouseEnter = this._onMouseEnter.bind(this)
    this._onMouseLeave = this._onMouseLeave.bind(this)
    this.componentDidMount = this.componentDidMount.bind(this)

    this.state = {
      current_item : 0, // curent item for auto transition
      CurrentActive: 1, // current active div
      buttonsState : this.listGenerator(0), // current active button  [0,0,1]

      FirstOpacity: '1',
      FirstImage  : this.images[0],

      SecondOpacity: '0',
      SecondImage  : this.images[0],
    }
  };

  componentDidMount () {
    // start timer
    //         this.timerId = setInterval(() => {
    //             this.setState({current_item: this.state.current_item + 1});
    //             this.switcher(((this.state.current_item % this.images.length) + this.images.length) % this.images.length);
    //
    //         }, timeout);

  }

  _onMouseMove (e) {
    this.setState({ x: e.touches[0].pageX })
  }

  _onMouseDown (e) {
    this.setState({ origin_x: e.touches[0].pageX, pushed: true })
    // clearTimeout(this.timerId);
    // setTimeout(() => {
    //
    //     this.timerId = setInterval(() => {
    //         this.setState({current_item: this.state.current_item + 1});
    //         this.switcher(((this.state.current_item % this.images.length) + this.images.length) % this.images.length);
    //
    //     }, timeout);
    //
    // }, 5000);
  }

  _onMouseUp (e) {
    // Swipe realisation
    if (this.state.pushed) {
      if (this.state.x - this.state.origin_x < 0) {
        this.setState({ current_item: this.state.current_item + 1 })
      } else if (this.state.x - this.state.origin_x > 0.5) {
        this.setState({ current_item: this.state.current_item - 1 })
      }

      this.switcher(((this.state.current_item % this.images.length) + this.images.length) % this.images.length)

      this.setState({ pushed: false })
    }
  }

  _onMouseEnter () {
    // pause transition if mouse on widget
    clearTimeout(this.timerId)
  }

  _onMouseLeave () {
    // start transition
    // this.timerId = setInterval(() => {
    //     this.setState({current_item: this.state.current_item + 1});
    //     this.switcher(((this.state.current_item % this.images.length) + this.images.length) % this.images.length);
    //
    // }, timeout);
  }

  listGenerator (num) {
    // generate button state: ["active", 0, 0]
    const b = []

    for (let i = 0; i < this.images.length; i++) {
      b.push('')
    }
    b[num] = 'slider_photo_active'

    return b
  }

  switcher (num) {
    // switch image on number

    this.setState({
      buttonsState: this.listGenerator(num),
      current_item: num,
    })

    if (this.state.CurrentActive === 1) {
      this.setState({
        CurrentActive: 0,

        FirstOpacity : '0',
        SecondOpacity: '1',

        SecondImage: this.images[num],

      })
    } else {
      this.setState({
        CurrentActive: 1,

        FirstOpacity : '1',
        SecondOpacity: '0',

        FirstImage: this.images[num],
      })
    }
  }

  buttonGenerator (num) {
    // generate buttons
    return (
      <li
        key={num}
        className={this.state.buttonsState[num]} onClick={() => this.switcher(num)}
      >
        <button className='animate_line'>

        </button>
      </li>
    )
  };

  render () {
    return (
      <div
        className='image_slider_widget'
        onMouseEnter={this._onMouseEnter}
        onMouseLeave={this._onMouseLeave}
      >

        <div className='title'>{this.title}</div>
        <div
          className='image'
          style={{ opacity: this.state.FirstOpacity }}
          onTouchMove={this._onMouseMove}
          onTouchStart={this._onMouseDown}
          onTouchEnd={this._onMouseUp}
          onTouchCancel={this._onMouseUp}
        >
          {this.state.FirstImage}

        </div>
        <div
          className='image'
          style={{ opacity: this.state.SecondOpacity }}
          onTouchMove={this._onMouseMove}
          onTouchStart={this._onMouseDown}
          onTouchEnd={this._onMouseUp}
          onTouchCancel={this._onMouseUp}
        >
          {this.state.SecondImage}

        </div>
        <div className='control'>
          <ul className='animation_config'>

            {this.images.map((Data, id) => this.buttonGenerator(id))}

          </ul>
        </div>

      </div>

    )
  }
}

export default SliderPhoto
