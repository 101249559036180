import { mediaURL } from 'global.js'
import { Menu } from 'components/layout/navigation'
import { SubscribeForm } from 'components/forms'

import { ReactComponent as FacebookIcon } from 'assets/facebook.svg'


export default function NavBarWidget () {
  return (
    <footer>
      <Menu />
      <div className='footer_subscribe'>
        <a
          className='social_network facebook' target='_blank'
          rel='noopener noreferrer'
          href='https://www.facebook.com/devchallengeua/'
        >
          <span>
            <FacebookIcon />
          </span>
        </a>
        <SubscribeForm />
      </div>
      <div className='logo-full-width'>
        <div className='inner_data_logo'>
          <div className='text_inner_data_logo'>
            <span id='organize-text'>Організатор:</span>
          </div>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.facebook.com/wechallengecompany'
          >
            <img
              src={`${mediaURL}/media/local/1101_wechallenge_logo.svg`}
              alt='wechallenge logo'
            />
          </a>
        </div>
      </div>
    </footer>
  )
}
