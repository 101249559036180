import { Component } from 'react'


class YouTubeWidget extends Component {
  render () {
    return (
      <div className='YouTubeWidget' style={{ height: '500px', width: '100%', float: 'left' }}>
        <iframe
          width='100%' height='100%'
          title='YouTubeFrame'
          src={this.props.item.href_youtube}
          frameBorder='0'
          allowFullScreen
        >
        </iframe>
      </div>
    )
  }
}
export default YouTubeWidget
