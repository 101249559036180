import axios from 'axios'
import Cookies from 'universal-cookie'

import { apiURL } from 'global.js'


const cookies = new Cookies()
const authToken = cookies.get('Token')

const server = axios.create({
  baseURL: apiURL,
  headers: {
    common: {
      Authorization: `Bearer ${authToken}`,
    },
  },
})


export default server
