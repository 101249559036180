import { Component } from 'react'


class BlockWithTextWidget extends Component {
  collect_slider () {
    const slider = []

    this.props.item.block_data.forEach((Element) => {
      slider.push(Element.name_of_additional)
    })

    return slider
  }

  constructor (props) {
    super(props)
    this.state = {
      title        : this.props.item.title,
      text         : this.props.item.text,
      slider       : this.collect_slider(),
      button       : '[ + ]',
      trigger      : false,
      slider_active: this.collect_slider()[0],
      style        : [
        'block-with-cards-main',
        'block-with-cards-title',
        'block-with-cards-text',
        'block-with-cards-button',
        'block-with-cards-slider',
      ],
    }
    this.collect_slider = this.collect_slider.bind(this)
    this.handleOnClick = this.handleOnClick.bind(this)
    this.handleSlider = this.handleSlider.bind(this)
  }

  handleOnClick () {
    if (this.state.trigger) {
      this.setState({
        style: [
          'block-with-cards-main',
          'block-with-cards-title',
          'block-with-cards-text',
          'block-with-cards-button',
          'block-with-cards-slider',
        ],
        trigger: false,
        button : '[ + ]',
      })
    } else {
      this.setState({
        style: [
          'block-with-cards-main-active',
          'block-with-cards-title-active',
          'block-with-cards-text-active',
          'block-with-cards-button-active',
          'block-with-cards-slider-active',
        ],
        trigger: true,
        button : 'X',
      })
    }
  }

  handleSlider (index) {
    this.setState({
      slider_active: this.state.slider[index],
    })
  }

  render () {
    return (
      <div className='block-with-cards'>
        <div
          className={this.state.style[0]}
        >
          {/* Title */}
          <h1 className={this.state.style[1]}>
            {this.state.title}
          </h1>
          {/* Main Text */}
          <div
            dangerouslySetInnerHTML={{ __html: this.state.text }} className={this.state.style[2] }
            style={this.state.text.length < 753 ? { height: 'auto' } : {}}
          />
          {/* Button */}
          {this.state.text.length > 753
                      ? (
                        <div
                          className={this.state.style[3]}
                          onClick={this.handleOnClick}
                        >
                          <p>
                            {this.state.button}
                          </p>
                        </div>
)
                      : null}
          {this.state.slider.length < 1
                      ? null
                      : (
                        <div className={this.state.style[4]} style={{ paddingTop: '10px' }}>
                          <p>{this.state.slider_active}</p>
                          <ul>
                            {this.state.slider.map((data, index) => (
                              <li
                                key={index}
                                onClick={() => this.handleSlider(index)}
                              >
                                <div>

                                </div>
                              </li>
                                  ),
                                )}
                          </ul>
                        </div>
)}
        </div>
      </div>
    )
  }
}

export default BlockWithTextWidget
