import { Component } from 'react'

import server from 'utils/api/server'
import Cookies from 'universal-cookie'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { getData, getFormData } from 'actions/user_actions'

import { ReactComponent as CalendarArrowIcon } from 'assets/calendar-arrow.svg'
import { ReactComponent as CalendarIcon } from 'assets/calendar.svg'
import { ReactComponent as LongArrowLeftIcon } from 'assets/long-arrow-left.svg'
import { ReactComponent as CheckmarkIcon } from 'assets/checkmark.svg'
import { ReactComponent as DotIcon } from 'assets/dot.svg'


class Calendar extends Component {
  constructor (props) {
    super(props)

    this.render = this.render.bind(this)

    this._onTouchDown = this._onTouchDown.bind(this)

    this.years_generator = this.years_generator.bind(this)

    this.months = ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень']

    this.state = {

      choice_calendar: (this.props.prefill ? this.props.prefill.text : undefined),
      calendar       : false,

      year : new Date().getFullYear(),
      month: new Date().getMonth(),

    }

    this.calendar_info = this.calendar_info.bind(this)
  };

  _onTouchDown (e) {
    if (this.calendar.contains(e.target)) {
      this.setState(
        {
          calendar: true,

        },
      )
    } else {
      this.setState(
        {
          calendar: false,
          year    : new Date().getFullYear(),
          month   : new Date().getMonth(),
        },
      )
    }
  };

  componentDidMount () {
    document.addEventListener('touchstart', this._onTouchDown, false)
    document.addEventListener('mousedown', this._onTouchDown, false)
    this.props.ParentCallback(this.state.choice_calendar)
  };

  componentWillUnmount () {
    document.removeEventListener('touchstart', this._onTouchDown, false)
    document.removeEventListener('mousedown', this._onTouchDown, false)
  }

  calendar_info (month, year) {
    const firstDay = (new Date(year, month)).getDay() - 1
    const daysInMonth = 32 - new Date(year, month, 32).getDate()

    const res = []
    let date = 1

    for (let i = 0; i < 6; i++) {
      // creates a table row
      const row = []

      // creating individual cells, filing them up with data.
      for (let j = 0; j < 7; j++) {
        if (i === 0 && j < firstDay) {
          row.push(<td>
            <div></div>
          </td>)
        } else if (date > daysInMonth) {
          break
        } else {
          row.push(<td align='center'>
            <div
              className={'upw_date_item upw_date_active'}

              onClick={
                            (e) => {
                              this.setState({
                                choice_calendar: `${((e.currentTarget.textContent < 10) ? '0' : '') + e.currentTarget.textContent}.${(parseInt(this.state.month, 10) < 9) ? '0' : ''}${parseInt(this.state.month, 10) + 1}.${this.state.year}`,
                                calendar       : false,
                              })
                              this.props.ParentCallback(`${((e.currentTarget.textContent < 10) ? '0' : '') + e.currentTarget.textContent}.${(parseInt(this.state.month, 10) < 9) ? '0' : ''}${parseInt(this.state.month, 10) + 1}.${this.state.year}`)
              }
}
            >
              {date}
            </div>
          </td>)
          date++
        }
      }

      res.push(<tr>{row}</tr>)
    }

    return (res)
  }

  years_generator () {
    const res = []
    const first = new Date().getFullYear() - 100
    const last = first + 101

    for (let i = first; i < last; i++) {
      res.push(<option value={i}>{i}</option>)
    }

    return res
  }

  render () {
    return (
      <div className='upw_form_calendar' tabIndex='0'>
        <div
          ref={calendar => { this.calendar = calendar }}
          className={`upw_choice_button${this.state.calendar ? ' upw_choice_active' : ''}`}
        >
          <div
            className={`upw_calendar_label${(this.state.calendar || this.state.choice_calendar) ? ' upw_calendar_label_active' : ''}`}
          >
            {this.props.info.Title}
          </div>
          <span
            className='upw_chosen_text'
          >
            {this.state.choice_calendar}
          </span>
          <div
            className='upw_calendar'
            style={{ display: this.state.calendar ? 'block' : 'none' }}
          >
            <div className='upw_calendar_title'>
              <select
                className='upw_calendar_label upw_month' value={this.months[this.state.month]}
                onChange={
                                (e) => {
                                  this.setState({
                                    month: this.months.indexOf(e.target.value),
                                  })
                }
                            }
              >
                <option value='Січень'>Січень,</option>
                <option value='Лютий'>Лютий,</option>
                <option value='Березень'>Березень,</option>
                <option value='Квітень'>Квітень,</option>
                <option value='Травень'>Травень,</option>
                <option value='Червень'>Червень,</option>
                <option value='Липень'>Липень,</option>
                <option value='Серпень'>Серпень,</option>
                <option value='Вересень'>Вересень,</option>
                <option value='Жовтень'>Жовтень,</option>
                <option value='Листопад'>Листопад,</option>
                <option value='Грудень'>Грудень,</option>
              </select>
              <select
                className='upw_calendar_label upw_year' value={this.state.year}
                onChange={
                                (e) => {
                                  this.setState({
                                    year: e.target.value,
                                  })
                }
}
              >
                {this.years_generator()}
              </select>
              <div
                className='upw_calendar_prev' onClick={
                                () => {
                                  let currentMonth = this.state.month
                                  let currentYear = this.state.year

                                  currentYear = (currentMonth === 0) ? currentYear - 1 : currentYear
                                  currentMonth = (currentMonth === 0) ? 11 : currentMonth - 1

                                  this.setState({
                                    year : currentYear,
                                    month: currentMonth,
                                  })
                }
                            }
              >

                <CalendarArrowIcon className='upw_calendar_arrow' />
              </div>
              <div
                className='upw_calendar_next'
                onClick={
                                     () => {
                                       let currentMonth = this.state.month
                                       let currentYear = this.state.year

                                       currentYear = (currentMonth === 11) ? currentYear + 1 : currentYear
                                       currentMonth = (currentMonth + 1) % 12

                                       this.setState({
                                         year : currentYear,
                                         month: currentMonth,
                                       })
                }
                                 }
              >
                <CalendarArrowIcon className='upw_calendar_arrow' />
              </div>
            </div>
            <table
              className='upw_calendar_table' cellPadding='0'
              cellSpacing='0'
            >
              <thead className='upw_head'>
                <tr>
                  <th>
                    <abbr
                      className='upw_head_item'
                      title='Понеділок'
                    >
                      Пн
                    </abbr>
                  </th>
                  <th>
                    <abbr
                      className='upw_head_item'
                      title='Вівторок'
                    >
                      Вт
                    </abbr>
                  </th>
                  <th>
                    <abbr
                      className='upw_head_item'
                      title='Середа'
                    >
                      Ср
                    </abbr>
                  </th>
                  <th>
                    <abbr
                      className='upw_head_item'
                      title='Четвер'
                    >
                      Чт
                    </abbr>
                  </th>
                  <th>
                    <abbr
                      className='upw_head_item'
                      title="П'ятниця"
                    >
                      Пт
                    </abbr>
                  </th>
                  <th>
                    <abbr
                      className='upw_head_item'
                      title='Субота'
                    >
                      Сб
                    </abbr>
                  </th>
                  <th>
                    <abbr
                      className='upw_head_item'
                      title='Неділя'
                    >
                      Нд
                    </abbr>
                  </th>
                </tr>
              </thead>
              <tbody className='upw_body'>
                {this.calendar_info(this.state.month, this.state.year)}
              </tbody>

            </table>
          </div>
          <CalendarIcon className='upw_calendar_logo' />
        </div>

      </div>
    )
  }
}

class TextField extends Component {
  constructor (props) {
    super(props)

    this._onTouchDown = this._onTouchDown.bind(this)

    this.render = this.render.bind(this)
    this.state = {
      text: this.props.prefill !== undefined ? this.props.prefill.text : undefined,
    }
    this.props.ParentCallback(this.props.prefill !== undefined ? this.props.prefill.text : undefined)
  };

  _onTouchDown (e) {
    if (this.text_field.contains(e.target)) {
      this.setState(
        { text_field: false },
      )
    } else {
      this.setState(
        { text_field: false },
      )
    }
  };

  componentDidMount () {
    // console.log(this.props.prefill, "PREFIL");
    document.addEventListener('touchstart', this._onTouchDown, false)
    document.addEventListener('mousedown', this._onTouchDown, false)
  };

  componentWillUnmount () {
    document.removeEventListener('touchstart', this._onTouchDown, false)
    document.removeEventListener('mousedown', this._onTouchDown, false)
  }

  render () {
    return (
      <div>
        <div className='upw_form_text_field'>
          <div
            ref={text_field => { this.text_field = text_field }}
            className={`upw_choice_button${this.state.text_field ? ' upw_choice_active' : ''}`}
          >
            <div
              className={`upw_text_field_label${(this.state.text_field || this.state.text !== undefined) ? ' upw_text_field_label_active' : ''}`}
            >
              {this.props.info.Title}
            </div>
            <input
              className='upw_text_field'
              value={this.state.text} onChange={(e) => {
                                 this.setState({ text: e.target.value })
                                 this.props.ParentCallback(e.target.value)
              }}
            />

          </div>
        </div>
      </div>

    )
  }
}

class FormWidget extends Component {
  constructor (props) {
    super(props)
    this.next_page = this.next_page.bind(this)
    this.submit = this.submit.bind(this)
    this.cookies = new Cookies()
    this.state = {
      before_submit: true,
      text_button  : 'Надіслати',
      collapsed    : false,
      logged_in    : this.cookies.get('Token') ? true : !!(false || this.cookies.get('TokenFb')),
      check_one    : false,
      check_two    : false,
      checkbox_ONE : true,
      form_info    : '',
      number_error : false,
      checkbox_TWO : true,
    }
  }

  componentDidMount () {
    if (this.state.logged_in) {
      this.props.dispatch(getData())
      this.props.dispatch(getFormData())
    } else {
      this.props.history.push('login')
    }
  }

  next_page () {
    const error = false

    if (!error) {
      this.setState({ number_error: false })
      this.setState({
        collapsed: !this.state.collapsed,
      })
    }
  }

  submit () {
    this.setState({
      text_button  : 'Іде надсилання',
      before_submit: false,
    })
    server.post('/api/posts/form/validate', {
      birthday              : this.state.res2,
      city                  : this.state.res3,
      skills                : this.state.res5,
      interest              : this.state.interest,
      other_projects_history: this.state.other_projects_history,
      github                : this.state.github,
      linkedin              : this.state.linkedin,
      facebook              : this.state.facebook,

    }).then((response) => {
      if (response.status === 200) {
        this.props.history.push('/auth')
        window.location.reload()
      }
    })
      .catch((_) => {
      })
  }

    Callback1 = (data) => {
      this.setState({ res1: data })
    };

    Callback2 = (data) => {
      this.setState({ res2: data })
    };

    Callback3 = (data) => {
      this.setState({ res3: data })
    };

    Callback5 = (data) => {
      this.setState({ res5: data })
    };

    Callback_interest = (data) => {
      this.setState({ interest: data })
    };

    Callback_facebook = (data) => {
      this.setState({ facebook: data })
    };

    Callback_github = (data) => {
      this.setState({ github: data })
    };

    Callback_linkedin = (data) => {
      this.setState({ linkedin: data })
    };

    Callback_other_projects_history = (data) => {
      this.setState({ other_projects_history: data })
    };

    render () {
      return (
        <div
          className='FormFidgetWrapper'
          style={{ counterReset: 'number' }}
        >
          <div className='imageFullFormBack'>
            <div className='imageFullFormBackInner'>
              <div className='imageFullFormBackHeader'>
                <h1>/Профіль учасника Чемпіонату</h1>
              </div>
              <div className='imageFullFormBackContent'>
                <p>
                  Заповніть форму,
                  <br />
                  та зможете обрати свій челендж
                </p>
              </div>
            </div>
          </div>
          <div className='wrapper_inner_form'>
            <div className='animationFatherDouble'>
              <div
                className='upw_content upw_content_first_page'
                style={this.state.collapsed
                               ? {
                                   right: '100%',

                                 }
                               : {
                                   left   : '0',
                                   display: (this.state.collapsed ? 'none' : 'block'),
                }}
              >
                {this.props.fetched

                              ? (
                                <div className='upw_inner_content'>
                                  <div className='upw_form_title'>
                                    <b>Час познайомитись ;)</b>
                                  </div>
                                  {this.props.fetched_form
                                     ? (
                                       <div className='upw_form_field'>
                                         <Calendar
                                           prefill={{ text: this.props.form_info.birthday }}
                                           ParentCallback={this.Callback2}
                                           info={{ Title: 'Дата народження' }}
                                         />
                                       </div>
)
                                     : null}
                                  {this.props.fetched_form
                                      ? (
                                        <div className='upw_form_field'>
                                          <TextField
                                            ParentCallback={this.Callback3}
                                            prefill={{ text: this.props.form_info.city }}
                                            info={{ Title: 'Місце проживання' }}
                                          />
                                        </div>
)
                                      : null}
                                  {this.props.fetched_form
                                      ? (
                                        <div className='upw_form_field'>
                                          <TextField
                                            ParentCallback={this.Callback_other_projects_history}
                                            prefill={{ text: this.props.form_info.other_projects_history }}
                                            info={{ Title: 'Участь у наших минулих проєктах:' }}
                                          />
                                        </div>
)
                                      : null}
                                  <div className='upw_form_field'>
                                    <div
                                      className={`upw_button upw_button_form${(this.state.other_projects_history && this.state.res2 && this.state.res3) ? '' : ' upw_button_disabled'}`}
                                      onClick={this.next_page}
                                    >
                                      Продовжити заповнення
                                    </div>
                                  </div>
                                </div>
)
                              : null}
              </div>
              <div
                className='upw_content_second_page'
                style={this.state.collapsed
                               ? {
                                   left: '0%',

                                 }
                               : {
                                   left: '100%',

                                   display: (this.state.collapsed ? 'block' : 'none'),
                }}
              >
                <div
                  className='upw_inner_content'
                >
                  <div
                    className='button-back'
                    onClick={this.next_page}
                  >
                    <LongArrowLeftIcon />
                    <h1>Назад</h1>
                  </div>
                  <h2 className='second-step-title'>
                    Залишилось
                    декілька кроків
                  </h2>
                  {this.props.fetched_form
                                  ? (
                                    <div className='upw_form_field'>
                                      <TextField

                                        ParentCallback={this.Callback5}
                                        prefill={{ text: this.props.form_info.skills }}
                                        info={{ Title: 'Ваші Навички' }}
                                      />
                                    </div>
)
                                  : null}
                  {this.props.fetched_form
                                  ? (
                                    <div className='upw_form_field'>
                                      <TextField

                                        ParentCallback={this.Callback_interest}
                                        prefill={{ text: this.props.form_info.interest }}
                                        info={{ Title: 'Ваші інтереси' }}
                                      />
                                    </div>
)
                                  : null}
                  {this.props.fetched_form
                                  ? (
                                    <div className='upw_form_field'>
                                      <TextField

                                        ParentCallback={this.Callback_linkedin}
                                        prefill={{ text: this.props.form_info.linked_in }}
                                        info={{ Title: 'LinkedIn профіль' }}
                                      />
                                    </div>
)
                                  : null}
                  {this.props.fetched_form
                                  ? (
                                    <div className='upw_form_field'>
                                      <TextField

                                        ParentCallback={this.Callback_github}
                                        prefill={{ text: this.props.form_info.github }}
                                        info={{ Title: 'GitHub профіль' }}
                                      />
                                    </div>
)
                                  : null}
                  {this.props.fetched_form
                                  ? (
                                    <div className='upw_form_field'>
                                      <TextField

                                        ParentCallback={this.Callback_facebook}
                                        prefill={{ text: this.props.form_info.facebook }}
                                        info={{ Title: 'Facebook профіль' }}
                                      />
                                    </div>
)
                                  : null}
                  <br />
                  <div
                    className='upw_form_field'
                    style={{
                                       marginTop: '10px',
                                       display  : 'inline-block',
                    }}
                  >
                    <label
                      className='container_for_mark_plus'
                    >
                      Я
                      погоджуюся з
                      {' '}
                      <a
                        target='_blank' rel='noopener noreferrer'
                        href='https://docs.google.com/document/d/1dR4WGkcCL3ooi4DovGLAt56UHNsz4NP0ON86VwLPnUg/edit'
                      >
                        Правилами
                      </a>
                      {' '}
                      Чемпіонату
                      DEV Challenge XVIII. Та підтверджую, що прийняття мною
                      Правил є укладанням відповідного
                      договору, як це передбачено Правилами в
                      частині регулювання прав на об’єкти
                      інтелектуальної власності.
                      <input
                        ref='RulesOne'
                        type='checkbox'
                        defaultChecked={false}
                        onChange={() => this.setState({ check_one: !this.state.check_one })}
                      />
                      <CheckmarkIcon className='checkmark' />
                      <DotIcon className='dot' />
                    </label>
                  </div>
                  <div
                    className='upw_form_field'
                    style={{
                                       marginTop: '40px',
                                       display  : 'inline-block',
                    }}
                  >
                    <label
                      className='container_for_mark_plus'
                    >
                      Даю
                      згоду на обробку та зберігання моїх
                      персональних даних в порядку,
                      визначених
                      {' '}
                      <a
                        target='_blank' rel='noopener noreferrer'
                        href='https://docs.google.com/document/d/1dR4WGkcCL3ooi4DovGLAt56UHNsz4NP0ON86VwLPnUg/edit'
                      >
                        Правилами
                      </a>
                      .
                      <input
                        ref='RulesTwo'
                        type='checkbox'
                        defaultChecked={false}
                        onChange={() => this.setState({ check_two: !this.state.check_two })}
                      />
                      <CheckmarkIcon className='checkmark' />
                      <DotIcon className='dot' />
                    </label>
                  </div>
                  <div className='upw_form_field'>
                    <div
                      className={`upw_button upw_button_form${(this.state.res2 && this.state.res3 && this.state.res5 && this.state.interest && this.state.check_one && this.state.check_two && this.state.before_submit) ? '' : ' upw_button_disabled'}`}
                      onClick={this.submit}
                    >
                      {this.state.text_button}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
}

function mapStateToProps (state) {
  return {
    user        : state.user,
    user_info   : state.user_info,
    fetched     : state.fetched,
    fetched_form: state.fetched_form,
    form_info   : state.form_info,
  }
}

export default connect(mapStateToProps)(withRouter(FormWidget))
