import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import Cookies from 'universal-cookie'


const cookies = new Cookies()
const initialState = {
  fetching     : false,
  user_info    : '',
  fetched      : false,
  fetched_form : false,
  fetching_form: false,
  user         : '',
  form_info    : '',
  login_error  : null,
  logged_in    : false,
  error        : null,
}

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
  case 'FETCH_USERS_START': {
    return {
      ...state, fetching: true,
    }
  }

  case 'FETCH_FORM_START': {
    return {
      ...state, fetching_form: true,
    }
  }

  case 'FETCH_USERS_ERROR': {
    cookies.remove('Token', { path: '/' })

    return {
      ...state,
      fetching : false,
      fetched  : true,
      error    : action.payload,
      logged_in: false,
    }
  }

  case 'LOGIN_ERROR': {
    return {
      ...state,
      fetching   : false,
      fetched    : true,
      login_error: action.payload,
      logged_in  : false,
    }
  }

  case 'RECEIVE_USERS': {
    return {
      ...state,
      fetching : false,
      fetched  : true,
      logged_in: true,
      user     : action.payload,
    }
  }

  case 'RECEIVE_FORM_INFO': {
    return {
      ...state,
      form_info   : action.payload,
      fetched_form: true,
    }
  }

  case 'RECEIVE_USERS_INFO': {
    return {
      ...state,
      fetching : false,
      fetched  : true,
      logged_in: true,
      user_info: action.payload,
    }
  }

  default: {
    return { ...state }
  }
  }
}

export const store = createStore(
  rootReducer,
  applyMiddleware(
    thunkMiddleware,
  ),
)
