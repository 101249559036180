import { Component } from 'react'
import 'whatwg-fetch'
import Cookies from 'universal-cookie'
import { connect } from 'react-redux'
import { Route, BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Post from './components/Posts'
import { apiURL } from './global'
import { getData } from './actions/user_actions'
import Preloader from './components/extra_parts/Preloader'


class App extends Component {
  constructor (props) {
    super(props)
    this.route = window.location.href.split('/')[3]
    this.cookies = new Cookies()
    this.state = { posts: [] }
  }

  loadPost () {
    const endpoint = `${apiURL}/api/posts/${this.route}`
    const thisComp = this
    const lookupOptions = {
      method : 'GET',
      headers: {
        'Content-type': 'applications/json',
        Authorization : 'Bearer '.concat(this.cookies.get('Token')),
      },
    }

    fetch(endpoint, lookupOptions)
      .then(function (response) {
        return response.json()
      })
      .then(function (responseData) {
        thisComp.setState({
          posts: responseData,
        })
      })
      .catch(function (_) {
      })
  }

  componentDidMount () {
    this.props.getData()
    this.loadPost()
  }

  render () {
    return (
      <>
        <Router>
          {this.props.fetched && this.state.posts !== []
                    ? (
                      <>
                        <div id='top-bar' className='top-bottom'>
                          <span>We_Challenge</span>
                        </div>
                        <div className='App'>
                          <Route
                            path='*' render={() => <Post widgets={this.state.posts} user_data={this.state.user_data} />} exact
                          />
                        </div>
                      </>
  )
                    : <Preloader />}
        </Router>
        <ToastContainer position='bottom-right' />
      </>
    )
  }
}

function mapStateToProps (state) {
  return {
    logged_in: state.logged_in,
    fetched  : state.fetched,
  }
}

export default connect(mapStateToProps, { getData })(App)
