import { createRef, Component } from 'react'

import { ReactComponent as FlagMiniIcon } from 'assets/flag-mini.svg'
import { ReactComponent as CloseIcon } from 'assets/close.svg'


class MiniTimeline extends Component {
  convertDate (date) {
    const splited = date.split('-')

    return `${splited[2]}.${splited[1]}.${splited[0]}`
  }

  dynamicSort (property) {
    let sortOrder = 1

    if (property[0] === '-') {
      sortOrder = -1
      property = property.substr(1)
    }

    return function (a, b) {
      const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0

      return result * sortOrder
    }
  }

  collect_events () {
    const data = [];

    (this.props.item.block_data).sort(this.dynamicSort('date')).map((item) => {
      data.push(
        {
          offset          : item.offset,
          date            : this.convertDate(item.date),
          title           : item.title,
          icon            : item.icon.file_image_svg,
          margin_convector: item.icon.name_of_the_file_image,
        })

      return 0
    },
    )

    return data
  }

  constructor (props) {
    super(props)
    this.window_size = createRef()
    this.render = this.render.bind(this)
    this.switcher = this.switcher.bind(this)
    this.eventGenerator = this.eventGenerator.bind(this)
    this.collect_events = this.collect_events.bind(this)
    this.convertDate = this.convertDate.bind(this)

    this.dynamicSort = this.dynamicSort.bind(this)
    // functions for hovering
    this._onMouseOver = this._onMouseOver.bind(this)
    this._onMouseOut = this._onMouseOut.bind(this)

    // functions for slide

    this._onTouchMove = this._onTouchMove.bind(this)
    this._onTouchUp = this._onTouchUp.bind(this)
    this._onTouchDown = this._onTouchDown.bind(this)

    this._onMouseMove = this._onMouseMove.bind(this)
    this._onMouseUp = this._onMouseUp.bind(this)
    this._onMouseDown = this._onMouseDown.bind(this)

    // functions for timer
    this.componentDidMount = this.componentDidMount.bind(this)
    this.countdown = this.countdown.bind(this)

    this.event_refs = []
    this.outside_click = this.outside_click.bind(this)

    // svg icon
    this.icon_flag = <FlagMiniIcon />

    // information for vidget
    this.info = {
      End_date: new Date('Sep 21, 2018 19:00:00').getTime(), // must be in this format - date for countdown

      Title : this.props.item.title, // title if countdown running
      Button: this.props.item.button_href && this.props.item.button_text ? [this.props.item.button_href, this.props.item.button_text] : [], // if equals [] - button will not display

      // description can have div inside, if element has class "additional" and window is smaller than 600px element will not display
      Description: <div
        dangerouslySetInnerHTML={{ __html: this.props.item.Description }}
                   />,
      Title_started: this.props.item.title_started, // title if countdown ended

      Events: this.collect_events(),
      // !!! IMPORTANT widget automaticly sets pointer for current step (line with arrows), thats why events must be sorted by date from smaller to greter
      // offset - can be "small", "medium", "large" - defines space between events, first events offset must be ""
      // date - must be in format dd.mm.year
      // title - text
      // icon - svg icon with (viewBox="0 0 42 42") atribute

    }

    // end information for widget

    // Compute length of MiniTimeline
    this.slider_length = 0
    for (let i = 0; i < this.info.Events.length; i++) {
      const res = this.info.Events[i].offset

      if (res === 'small') {
        this.slider_length += (90 + 45)
      } else if (res === 'medium') {
        this.slider_length += (180 + 45)
      } else if (res === 'large') {
        this.slider_length += (270 + 45)
      } else {
        this.slider_length += (45 + 45)
      }
    }
    this.slider_length += 45
    this.old_length = this.slider_length
    this.events_info = []

    this.state = {
      active_event    : -1, // current active element
      hovered_element : -1, // current hovered element
      // variables for slide
      slide           : 0,
      slider_offset   : 0,
      transition_time : 0,
      // end of ariables for slide
      regular_title   : this.info.Title,
      current_title   : this.info.Title,
      pointer_position: 67,
      today           : { display: 'none' },
      line_position   : 0, // for positioning of popups

    }
  }
  ;

  countdown (first) {
    // countdown

    // pointer positioning

    const date_first = this.info.Events[0].date
    const now = new Date().getTime()
    let offset = -22

    if (now < new Date(`${date_first.substr(3, 2)}.${date_first.substr(0, 2)}.${date_first.substr(6, 4)} 00:00:00`).getTime()) {
      offset = 67
      this.slider_length = this.old_length + 90
      this.setState({ today: {} })
    } else {
      this.setState({ today: { display: 'none' } })
      offset = -22
    }

    if (first) {
      for (let i = 0; i < this.info.Events.length; i++) {
        const date_full = this.info.Events[i].date

        const res = this.info.Events[i].offset

        if (res === 'small') {
          offset += (90 + 45)
        } else if (res === 'medium') {
          offset += (180 + 45)
        } else if (res === 'large') {
          offset += (270 + 45)
        } else {
          offset += (45 + 45)
        }

        const year = date_full.substr(6, 4)
        const month = date_full.substr(3, 2)
        const day = date_full.substr(0, 2)
        const date = new Date(year, month - 1, day)

        this.events_info.push({
          date  : date.getTime(),
          offset: offset,
        })
      }

      // console.log(this.events_info)
    }

    for (let i = 0; i < this.events_info.length; i++) {
      if (now >= this.events_info[i].date) {
        this.setState({ pointer_position: this.events_info[i].offset })
      }
    }

    // for (let i = 0; i < this.events_info.length; i++) {
    //     if (now > this.events_info[i].date) {
    //         this.setState({pointer_position: this.events_info[i].offset});

    //     }
    // }

    // timer
    const distance = this.info.End_date - now

    const days = Math.floor(distance / (1000 * 60 * 60 * 24))
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((distance % (1000 * 60)) / 1000)

    this.setState({
      current_title: `${this.info.Title + (days !== 0 ? ` ${days.toString()} ` : '') + (days !== 0 ? (days.toString()[days.toString().length - 1] === '1' ? ((days === 11) ? 'днів' : 'день') : ((days > 4) ? 'днів' : 'дні')) : '')} ${hours}:${
        minutes}:${seconds}`,
    })

    if (distance < 0) {
      clearInterval(this.timer)
      this.setState({ current_title: this.info.Title_started })
    }
  }

  componentDidMount () {
    this.countdown(true)
    this.timer = setInterval(() => this.countdown(false), 1000)
    document.addEventListener('touchstart', this.outside_click, false)
    document.addEventListener('mousedown', this.outside_click, false)
  }

  // Hover
  _onMouseOver (num) {
    this.setState({ hovered_element: num })
  }

  _onMouseOut () {
    this.setState({ hovered_element: -1 })
  }

  // Swipe for PC

  _onMouseMove (e) {
    if (this.state.pushed) {
      this.setState({ slide: e.pageX - this.state.origin_x + this.state.slider_offset })
    }
  }

  _onMouseDown (e) {
    this.setState({
      origin_x       : e.pageX,
      pushed         : true,
      transition_time: '0ms',
    })
  }

  _onMouseUp () {
    if (this.state.pushed) {
      this.setState({
        pushed       : false,
        slider_offset: this.state.slide,
      })

      if (this.state.slide > 0) {
        this.setState({
          slide          : 0,
          slider_offset  : 0,
          transition_time: '500ms',
        })
      }

      if (this.state.slide + this.slider_length - this.window_size.current.offsetWidth < 0) {
        this.setState({
          slide          : this.slider_length > this.window_size.current.offsetWidth ? this.window_size.current.offsetWidth - this.slider_length : 0,
          slider_offset  : this.slider_length > this.window_size.current.offsetWidth ? this.window_size.current.offsetWidth - this.slider_length : 0,
          transition_time: '500ms',
        })
      }
    }
  }

  // Swipe for smartphones
  _onTouchMove (e) {
    this._onMouseMove(e.touches[0])
  }

  _onTouchDown (e) {
    this._onMouseDown(e.touches[0])
  }

  _onTouchUp () {
    this._onMouseUp()
  }

  outside_click (e) {
    for (let i = 0; i < this.event_refs.length; i++) {
      if (this.event_refs[i].contains(e.target)) {
        return (true)
      }
    }
    this.setState(
      { active_event: -1 },
    )
  }

  // popup switcher
  switcher (num, e) {
    const widget = this.window_size.current
    const widgetWidth = widget.offsetWidth
    const buttonXOffset = e.target.getBoundingClientRect().x - widget.getBoundingClientRect().x
    let line_position = ''

    if (buttonXOffset <= widgetWidth / 3) {
      line_position = 'start'
    } else if (buttonXOffset <= (widgetWidth / 3) * 2) {
      line_position = 'center'
    } else if (buttonXOffset <= widgetWidth) {
      line_position = 'end'
    }

    this.setState({
      active_event : num,
      line_position: line_position,
    })
  }

  eventGenerator (num) {
    // put events on MiniTimeline
    return (

      <div
        ref={(ref) => { this.event_refs[num] = ref }}
        className={`event ${num === 0 ? 'first' : this.info.Events[num].offset}${this.state.active_event === num ? (` active_time_line ${this.state.line_position}`) : ''}${num === this.info.Events.length - 1 ? ' time_line_last' : ''}`}
      >
        <div className='popup'>
          <div
            className='icon'
          >
            <img
              src={this.info.Events[num].icon}
              alt='timeline_icon'
            />
          </div>
          <div
            className='date_time_line'
          >
            {this.info.Events[num].date.substr(0, 5)}
          </div>
          <div className='title_time_line'>{this.info.Events[num].title}</div>
          <div
            className='button'
            onClick={(e) => this.switcher(-1, e)}
          >
            <CloseIcon />
          </div>

        </div>
        <div className='icon'>

          {this.info.Events[num].margin_convector === 'Прапор' || this.info.Events[num].margin_convector === 'Прапор-фл'
                      ? (
                        <img
                          src={this.info.Events[num].icon}
                          alt='timeline_icon'
                          style={{
                               width     : '40px',
                               height    : '40px',
                               marginLeft: '3px',
                          }}
                        />
)
                      : (
                        <img
                          src={this.info.Events[num].icon}
                          alt='timeline_icon'
                          style={{
                                           width     : '40px',
                                           height    : '40px',
                                           marginLeft: '3px',
                          }}
                        />
)}
        </div>
        <div
          className='date_time_line'
        >
          {this.info.Events[num].date.substr(0, 5)}
        </div>
        <div
          className='circle'
          style={this.state.hovered_element === num
                       ? {
                           transform      : 'scale(1.13)',
                           backgroundColor: 'rgba(0,0,0,0.2)',
                         }
                       : {}}
        >

        </div>
        <div
          className='button'
          onMouseOver={() => this._onMouseOver(num)}
          onMouseOut={() => this._onMouseOut()}
          onClick={(e) => this.switcher(num, e)}
        >

        </div>

      </div>

    )
  }
  ;

  render () {
    return (

      <div ref={this.window_size} className='event_slider_widget'>

        <div
          className='title_time_line'
        >
          {this.state.regular_title}
        </div>
        <div className='description'>
          {this.info.Description}
          {this.info.Button.length === 0
                      ? undefined
                      : (
                        <a
                          href={this.info.Button[0]}
                          className='button'
                        >
                          {this.info.Button[1]}
                        </a>
)}
        </div>
        <div
          className='slider' style={{
                  transform         : `translate(${this.state.slide}px, 0px) translateZ(0px)`,
                  transitionDuration: this.state.transition_time,
          }}
          onMouseMove={this._onMouseMove}
          onMouseDown={this._onMouseDown}
          onMouseUp={this._onMouseUp}
          onMouseLeave={this._onMouseUp}
          onTouchMove={this._onTouchMove}
          onTouchStart={this._onTouchDown}
          onTouchEnd={this._onTouchUp}
          onTouchCancel={this._onTouchUp}
        >
          <div
            className='pointer'
            style={{ left: this.state.pointer_position }}
          >

          </div>
          <div className='event today' style={this.state.today}>
            <div className='circle'>

            </div>
          </div>
          {this.info.Events.map((Data, id) => this.eventGenerator(id))}

        </div>

      </div>

    )
  }
}

export default MiniTimeline
