import { useLocation } from 'react-router-dom'

import styles from './Menu.module.css'


export interface Item {
  title: string
  href: string
}

export const items: Item[] = [
  { title: 'DEV Challenge', href: '/' },
  { title: 'Challenges', href: '/challenges' },
  { title: 'Партнери', href: '/partners' },
  { title: 'Судді', href: '/judges' },
  { title: "Зв'язок", href: '/feedback' },
]

export default function Menu (props: any) {
  const location = useLocation()
  const currentPathname = location.pathname

  const menuItems = items.map((item: Item) => {
    const isActive = currentPathname === item.href

    return (
      <li key={item.title}>
        <a
          href={item.href} style={{
          textDecoration: 'none',
          color         : 'black',
          }}
        >
          <span className={`navigation-span__cell${isActive ? ' active_nav_item' : ''}`}>
            <span
              className='child_container_navs'
            >
              {item.title}
            </span>
          </span>
        </a>
      </li>
    )
  })

  return (
    <ul
      className={styles.menu} id='container_navs' style={{
      display  : 'flex',
      width    : '100%',
      float    : 'none',
      margin   : '0',
      textAlign: 'center',
      padding  : '30px',
      }} {...props}
    >
      {menuItems}
    </ul>
  )
}
