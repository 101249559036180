import { ReactNode } from 'react'

import styles from './Carts.module.css'


export interface Judge {
  name: ReactNode
  category: ReactNode
  description: ReactNode
  imageSrc: string
}

export interface Props {
  headline: string,
  judges: Judge[]
}

export default function Carts (props: Props) {
  const { headline, judges } = props

  return (
    <>
      {headline
        && (<div className={styles.headline}>
          {headline}
        </div>)}
      <div className={styles.judges}>
        {judges.map((judge, index) => (
          <div key={index} className={styles.judge}>
            <div className={styles.judgeImage}>
              <img src={judge.imageSrc} />
            </div>
            <div className={styles.judgeName}>{judge.name}</div>
            <div className={styles.judgeCategory}>{judge.category}</div>
            <div className={styles.judgeDescription}>{judge.description}</div>
          </div>
        ))}
      </div>
    </>
  )
}
