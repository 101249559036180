import { Component } from 'react'

import { ReactComponent as TriangleIcon } from 'assets/triangle.svg'


class DropdownList extends Component {
  constructor (props) {
    super(props)
    this._onTouchDown = this._onTouchDown.bind(this)
    this.render = this.render.bind(this)
    this.state = {
      choice  : (this.props.prefill ? this.props.prefill.text : undefined),
      dropdown: false,
    }
  };

  _onTouchDown (e) {
    if (this.dropdown.contains(e.target)) {
      this.setState(
        { dropdown: true },
      )
    } else {
      this.setState(
        { dropdown: false },
      )
    }
  };

  componentDidMount () {
    document.addEventListener('touchstart', this._onTouchDown, false)
    document.addEventListener('mousedown', this._onTouchDown, false)
    this.props.ParentCallback(this.state.choice)
  };

  componentWillUnmount () {
    document.removeEventListener('touchstart', this._onTouchDown, false)
    document.removeEventListener('mousedown', this._onTouchDown, false)
  }

  render () {
    return (
      <div>
        <div className='upw_form_dropdown' tabIndex='0'>
          <div
            ref={dropdown => { this.dropdown = dropdown }}
            className='upw_choice_button'
          >
            <div
              className={`upw_dropdown_label${(this.state.dropdown || this.state.choice !== undefined) ? ' upw_dropdown_label_active' : ''}`}
            >
              {this.props.info.Title}
            </div>
            <span
              className='upw_chosen_text'
            >
              {this.state.choice}
            </span>
            <TriangleIcon className='upw_select_triangle' />
            <div
              className='upw_list'
              style={{ display: this.state.dropdown ? 'block' : 'none' }}
            >
              {this.props.info.Items.map((inp) => (
                <div
                  className={`upw_element${(this.state.choice === inp) ? ' upw_list_selected' : ''}`}
                  onClick={
                                        () => {
                                          this.setState({
                                            choice  : inp,
                                            dropdown: false,
                                          })
                                          this.props.ParentCallback(inp)
                  }
                                    }
                >
                  {inp}
                </div>
                              ),
                            )}
            </div>
          </div>
        </div>
      </div>

    )
  }
}
export default DropdownList
