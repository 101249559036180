import { Component } from 'react'
import server from 'utils/api/server'
import Cookies from 'universal-cookie'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { getData, getDesignData } from 'actions/user_actions'
import DropdownList from '../form_elements/DropDownList'
import TextField from '../form_elements/TextField'


class DesignForm extends Component {
  constructor (props) {
    super(props)
    this.next_page = this.next_page.bind(this)
    this.submit = this.submit.bind(this)
    this.cookies = new Cookies()
    this.state = {
      before_submit: true,
      text_button  : 'Надіслати',
      collapsed    : false,
      logged_in    : this.cookies.get('Token') ? true : !!(false || this.cookies.get('TokenFb')),
      check_one    : false,
      check_two    : false,
      checkbox_ONE : true,
      form_info    : '',
      number_error : false,
      checkbox_TWO : true,
    }
  }

  componentDidMount () {
    if (this.state.logged_in) {
      this.props.dispatch(getData())
      this.props.dispatch(getDesignData())
    } else {
      this.props.history.push('login')
    }
  }

  next_page () {
    const error = false

    if (!error) {
      this.setState({ number_error: false })
      this.setState({
        collapsed: !this.state.collapsed,
      })
    }
  }

  submit () {
    this.setState({
      text_button  : 'Іде надсилання',
      before_submit: false,
    })
    console.log(this.props.form_info)
    server.post('/api/posts/form/design_form', {
      nomination      : this.state.nomination,
      professional_lvl: this.state.professional_lvl,
      personal_website: this.state.personal_website,
      portfolio       : this.state.portfolio,
    })
      .then((response) => {
        if (response.status === 200) {
          this.props.history.push('/auth')
          window.location.reload()
        }
      })
      .catch((_) => {})
  }


    Callback_nomination= (data) => {
      this.setState({ nomination: data })
    };

     Callback_portfolio= (data) => {
       this.setState({ portfolio: data })
     };

    Callback_personal_website= (data) => {
      this.setState({ personal_website: data })
    };

    Callback_proffesional_lvl= (data) => {
      this.setState({ professional_lvl: data })
    };

    render () {
      return (
        <div
          className='FormFidgetWrapper'
          style={{ counterReset: 'number' }}
        >
          <div className='imageFullFormBack'>
            <div className='imageFullFormBackInner'>
              <div className='imageFullFormBackHeader'>
                <h1>/ Вибір номінації DEV Challenge XVIII</h1>
              </div>
              <div className='imageFullFormBackContent'>
                <p>Обери свій челендж на цей сезон</p>
              </div>
            </div>
          </div>
          <div className='wrapper_inner_form'>
            <div className='animationFatherDouble'>
              <div
                className='upw_content upw_content_first_page'
                style={this.state.collapsed
                               ? {
                                   right: '100%',
                                 }
                               : {
                                   left   : '0',
                                   display: (this.state.collapsed ? 'none' : 'block'),
                }}
              >
                {this.props.fetched

                              ? (
                                <div
                                  className='upw_inner_content'
                                >

                                  <div className='upw_form_title'>
                                    Чотири поля і, як кажуть, #devchallengeaccepted
                                  </div>
                                  {this.props.fetched_form
                                          ? (
                                            <div className='upw_form_field'>
                                              <DropdownList
                                                prefill={{ text: this.props.form_info.nomination }}
                                                ParentCallback={this.Callback_nomination}
                                                info={{
                                              Title: 'Номінація',
                                              Items: ['Design APP', 'Design: Product / UX'],
                                                }}
                                              />
                                            </div>
)
                                          : null}
                                  {this.props.fetched_form
                                          ? (
                                            <div className='upw_form_field'>
                                              <DropdownList
                                                prefill={{ text: this.props.form_info.professional_lvl }}
                                                ParentCallback={this.Callback_proffesional_lvl}
                                                info={{
                                              Title: 'Професіний рівень:',
                                              Items: ['Junior', 'Middle', 'Senior'],
                                                }}
                                              />
                                            </div>
)
                                          : null}
                                  {this.props.fetched_form
                                  ? (
                                    <div className='upw_form_field'>
                                      <TextField
                                        ParentCallback={this.Callback_portfolio}
                                        prefill={{ text: this.props.form_info.portfolio }}
                                        info={{ Title: 'Посилання на портфоліо' }}
                                      />
                                    </div>
)
                                  : null}
                                  {this.props.fetched_form
                                  ? (
                                    <div className='upw_form_field'>
                                      <TextField

                                        ParentCallback={this.Callback_personal_website}
                                        prefill={{ text: this.props.form_info.personal_website }}
                                        info={{ Title: 'Персональний сайт' }}
                                      />
                                    </div>
)
                                  : null}
                                  <div className='upw_form_field'>
                                    <div
                                      className={'upw_button upw_button_form ' }
                                      onClick={this.submit}
                                    >
                                      {this.state.text_button}
                                    </div>
                                  </div>

                                </div>
)
                              : null}
              </div>

            </div>
          </div>
        </div>
      )
    }
}

function mapStateToProps (state) {
  return {
    user        : state.user,
    user_info   : state.user_info,
    fetched     : state.fetched,
    fetched_form: state.fetched_form,
    form_info   : state.form_info,
  }
}

export default connect(mapStateToProps)(withRouter(DesignForm))
