import { Carts } from 'components/layout/judges'


export interface Props {
  item: {
    headline: string
    block_data: DataItem[]
  }
}

export interface DataItem {
  heading: string
  extra_about: string
  text: string
  image: {
    file_image_svg: string
  }
}

function dynamicSort (property: string) {
  let sortOrder = 1

  if (property[0] === '-') {
    sortOrder = -1
    property = property.substr(1)
  }

  return function (a: any, b: any) {
    const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0

    return result * sortOrder
  }
}

export default function CartsWidget (props: Props) {
  const { item } = props

  return (
    <Carts
      headline={item.headline}
      judges={item.block_data.sort(dynamicSort('order')).map((dataItem) => {
        return {
          name       : dataItem.heading,
          category   : dataItem.extra_about,
          description: <p dangerouslySetInnerHTML={{ __html: dataItem.text }} />,
          imageSrc   : dataItem.image.file_image_svg,
        }
      })}
    />
  )
}
