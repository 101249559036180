import { Component } from 'react'
import server from 'utils/api/server'
import Cookies from 'universal-cookie'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { getTeamFormXVII } from 'actions/user_actions'
import TextField from 'components/form_elements/TextField'
import Boolean from 'components/form_elements/Boolean'


class TeamFormXVII extends Component {
  constructor (props) {
    super(props)
    this.next_page = this.next_page.bind(this)
    this.submit = this.submit.bind(this)
    this.cookies = new Cookies()
    this.state = {
      before_submit: true,
      text_button  : 'Надіслати',
      collapsed    : false,
      logged_in    : this.cookies.get('Token') ? true : !!(false || this.cookies.get('TokenFb')),
      form_info    : '',
      number_error : false,
      devops       : false,
    }
  }

  componentDidMount () {
    if (this.state.logged_in) {
      this.props.dispatch(getTeamFormXVII())
    } else {
      this.props.history.push('login')
    }
  }

  next_page () {
    const error = false

    if (!error) {
      this.setState({ number_error: false })
      this.setState({
        collapsed: !this.state.collapsed,
      })
    }
  }

  submit () {
    this.setState({
      text_button  : 'Іде надсилання',
      before_submit: false,
    })
    const body = {
      team_name      : this.state.team_name,
      team_status    : this.state.team_status,
      is_team_open   : this.state.is_team_open,
      team_needs_help: this.state.team_needs_help,
    }

    server.post('/api/posts/form/devch_xvii_team_form', body).then((response) => {
      if (response.status === 200) {
        this.props.history.push('/auth')
        window.location.reload()
      }
    })
      .catch((_) => {
      })
  }

    Callback_team = (data) => {
      this.setState({ team_name: data })
    };

    Callback_boolean = (data) => {
      this.setState(data)
    };

    render () {
      return (
        <div
          className='FormFidgetWrapper'
          style={{ counterReset: 'number' }}
        >
          <div className='imageFullFormBack'>
            <div className='imageFullFormBackInner'>
              <div className='imageFullFormBackHeader'>
                <h1>/ Вибір номінації DEV Challenge XVIII</h1>
              </div>
              <div className='imageFullFormBackContent'>
                <p>Обери свій челендж на цей сезон</p>
              </div>
            </div>
          </div>
          <div className='wrapper_inner_form'>
            <div className='animationFatherDouble'>
              <div
                className='upw_content upw_content_first_page'
                style={this.state.collapsed
                              ? {
                                  right: '100%',
                                }
                              : {
                                  left   : '0',
                                  display: (this.state.collapsed ? 'none' : 'block'),
                }}
              >
                {this.props.fetched
                              ? (
                                <div className='upw_inner_content'>
                                  <div className='upw_form_title'>
                                    Анкета на номінацію Team
                                  </div>
                                  {this.props.fetched_form
                                      ? (
                                        <div className='upw_form_field'>
                                          <TextField

                                            ParentCallback={this.Callback_team}
                                            prefill={{ text: this.props.form_info.team_name }}
                                            info={{ Title: 'Назва Команди' }}
                                          />
                                        </div>
)
                                      : null}
                                  {this.props.fetched_form
                                      ? (
                                        <>
                                          <Boolean callback={this.Callback_boolean} default_value={this.props.form_info.team_status} key_name='team_status' label={'У мене зібрана Команда'} />
                                          <Boolean callback={this.Callback_boolean} default_value={this.props.form_info.is_team_open} key_name='is_team_open' label={'Наша Команда Відкрита для нових учасників'} />
                                          <Boolean callback={this.Callback_boolean} default_value={this.props.form_info.needs_team_help} key_name='needs_team_help' label={'Допоможіть знайти команду'} />
                                        </>
)
                                      : null}
                                  <div className='upw_form_field'>
                                    <div
                                      className={'upw_button upw_button_form '}
                                      onClick={this.submit}
                                    >
                                      {this.state.text_button}
                                    </div>
                                  </div>
                                </div>
)
                              : null}
              </div>
            </div>
          </div>
        </div>
      )
    }
}

function mapStateToProps (state) {
  return {
    user        : state.user,
    user_info   : state.user_info,
    fetched     : state.fetched,
    fetched_form: state.fetched_form,
    form_info   : state.form_info,
  }
}

export default connect(mapStateToProps)(withRouter(TeamFormXVII))
