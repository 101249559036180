import { Component } from 'react'

import { ReactComponent as CheckmarkIcon } from 'assets/checkmark.svg'
import { ReactComponent as DotIcon } from 'assets/dot.svg'


class Boolean extends Component {
  componentDidMount () {
    this.callbackSet()
  }

    callbackSet = () => {
      const data = {}

      data[this.props.key_name] = this.refs.checkbox.checked
      this.props.callback(data)
    }

    render () {
      return (
        <div className='upw_form_field' style={{ marginTop: '25px', minHeight: '30px', display: this.props.show === false ? 'none' : '' }}>
          <label
            className='container_for_mark_plus'
          >
            {' '}
            {this.props.label}
            <input
              ref='checkbox'
              type='checkbox'
              defaultChecked={this.props.default_value}
              onChange={this.callbackSet}
            />
            <CheckmarkIcon ref='checkBoxRel' className='checkmark' />
            <DotIcon className='dot' />
          </label>
        </div>
      )
    }
}
export default Boolean
