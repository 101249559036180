import { Component } from 'react'


class CustomWidget extends Component {
  render () {
    return (
      <div style={{ backgroundColor: '#ffd200', width: '100%', float: 'left', padding: '150px' }}>
        <div
          id='mlb2-10547096'
          className='ml-subscribe-form ml-subscribe-form-10547096'
        >
          <div className='ml-vertical-align-center' style={{ textAlign: 'center' }}>
            <div
              className='subscribe-form ml-block-success'
              style={{ display: 'none' }}
            >
              <div className='form-section'>
                <p>Готово!</p>
              </div>
            </div>
            <form
              className='ml-block-form'
              action='https://landing.mailerlite.com/webforms/submit/k1y5v4'
              data-id='1109438' data-code='k1y5v4'
              method='POST' target='_blank'
            >
              <div className='subscribe-form'>
                <div className='form-section'>
                  <div
                    className='form-group ml-field-email ml-validate-required ml-validate-email'
                  >
                    <input
                      type='email'
                      name='fields[email]'
                      className='form-control'
                      placeholder='Email*' value=''
                      autoComplete='email'
                                               // x-autocompletetype="email"
                      spellCheck='false'
                      autoCapitalize='off'
                      autoCorrect='off'
                    />
                  </div>
                  <div
                    className='form-group ml-field-nomination11'
                  >
                    <input
                      type='text'
                      name='fields[nomination11]'
                      className='form-control'
                      placeholder='Які технології цікавлять?'
                      value='' spellCheck='false'
                      autoCapitalize='off'
                      autoCorrect='off'
                    />
                  </div>
                  <div className='form-group ml-field-phone'>
                    <input
                      type='text' name='fields[phone]'
                      className='form-control'
                      placeholder='Номер телефону / якщо зручно у telegram'
                      value=''
                      spellCheck='false'
                      autoComplete='off'
                      autoCorrect='off'
                    />
                  </div>
                </div>
                <input
                  type='hidden' name='ml-submit'
                  value='1'
                />
                <button type='submit' className='primary'>
                  Підтвердити
                </button>
                <button
                  disabled='disabled'
                  style={{ display: 'none' }}
                  type='button'
                  className='loading'
                >
                  <img
                    src='https://static.mailerlite.com/images/rolling@2x.gif'
                    alt=''
                    width='20' height='20'
                    style={{ width: '20px', height: '20px' }}
                  />
                </button>
              </div>
            </form>
          </div>
        </div>

      </div>
    )
  }
}

export default CustomWidget
