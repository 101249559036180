import * as yup from 'yup'


yup.setLocale({
  mixed: {
    default: 'Некоректні дані',
  },
  string: {
    email: 'Введіть коректний e-mail',
  },
})

const schema = yup.object().shape({
  email: yup
    .string()
    .required()
    .email(),
})

export default schema
