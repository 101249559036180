import { Component } from 'react'
import Cookies from 'universal-cookie'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { getFormData, getData } from 'actions/user_actions'
import FormEnterUpdate from 'components/extra_parts/FormEnterUpdate'
import GetCurrentTournament from 'components/tournaments/GetCurrentTournament'


const home = (
  <>
    <p>
      Вітаємо у DEV Challenge Community!
    </p>
    <br />
    <p>
      Щоб стати на крок ближче до участі у Чемпіонаті, заповніть, будь ласка, свій профіль. Після цього обирайте номінацію, у якій готові змагатися.
    </p>
  </>
)

class NewProfile extends Component {
  constructor (props) {
    super(props)
    this.cookies = new Cookies()
    this.text_state = 0
    this.state = {
      current_page: 'home',
    }
  };

  componentDidMount () {
    if (this.props.logged_in) {
      this.props.dispatch(getFormData())
      this.props.dispatch(getData())
    } else {
      this.props.history.push('login')
      window.location.reload()
    }
  };

    changeTournamentState = (state) => {
      this.props.history.push(state)
      window.location.reload()
    };

    changeProfileView = (state) => {
      this.state.setState({ current_page: state })
    }

    render () {
      if (this.props.fetched) {
        this.text_state = this.props.user_inf.form ? 'form_updated' : 'home'
      }

      return (
        <div className='main_profile'>
          {this.props.fetched
                  ? (
                    <div className='main_profile__wrapper'>
                      <div className='main_profile__text-area'>
                        <div className='main_profile__header'>
                          <div
                            className='main_profile__header-left'
                          >
                            <span
                              className='main_profile__big-slesh'
                            >
                              /
                            </span>
                            DEV Challenge Community
                          </div>
                          <div
                            className='main_profile__header-right'
                          >
                            id:
                            {this.props.user_inf.user_id}
                          </div>
                        </div>
                        {this.props.fetched_form && this.props.user_inf.form
                              ? (
                                <GetCurrentTournament
                                  user_inf={this.props.user_inf}
                                  current_page={this.text_state}
                                />
)
                              : home}

                      </div>
                      {this.state.current_page !== 'home'
                          ? null
                          : (
                            <div className='main_profile__button-area'>
                              {this.props.user_inf.form
                                  ? null
                                  : (
                                    <FormEnterUpdate
                                      updated={this.props.user_inf.form}
                                    />
)}
                            </div>
)}
                    </div>
)

                  : null}
        </div>
      )
    }
}

function mapStateToProps (state) {
  return {
    user        : state.user,
    user_inf    : state.user_info,
    logged_in   : state.logged_in,
    fetched     : state.fetched,
    fetched_form: state.fetched_form,
    form_info   : state.form_info,
  }
}

export default connect(mapStateToProps)(withRouter(NewProfile))
