import { Component } from 'react'
import Cookies from 'universal-cookie'
import server from 'utils/api/server'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import { withRouter } from 'react-router-dom'

import { logIn } from 'actions/user_actions'


class RegistrationWidget extends Component {
  constructor (props) {
    super(props)
    this.validate_empty = this.validate_empty()
    this.register = this.register.bind(this)
    this.registrate_user = this.registrate_user.bind(this)
    this.cookies = new Cookies()
    this.state = {

      number_error        : false,
      name_error          : false,
      same_passwords_error: false,
      email_error         : false,

      upw_name           : undefined,
      upw_mail           : undefined,
      upw_phone          : undefined,
      upw_pass           : undefined,
      upw_pass_repeat    : undefined,
      email_error_content: 'Введіть коректний e-mail',

      upw_is_sending: false,
    }
  }

  getFbToken (email, FB_ID) {
    const info = {
      email: email,
      FB_ID: FB_ID,
    }

    server.post('/api/posts/token/facebook', info).then(res => {
      this.cookies.set('Token', res.data.token, { path: '/' })
      this.props.history.push('/auth')
    })
      .catch((_) => {
      })
  }

    responseFacebook = response => {
      const info = {
        username    : response.name,
        password    : '98238509822746090435944972894080312675384980714153',
        mobile_phone: 'Facebook User',
        FB_ID       : response.id,
        email       : response.email,
      }

      server.post('/api/posts/register', info).then(res => {
        this.getFbToken(response.email, response.id)
      })
        .catch((_) => {
          this.getFbToken(response.email, response.id)
        })
    };

    registrate_user (log, pass, pass_match, email, mobile_phone) {
      const info = {
        username    : log,
        password    : pass,
        mobile_phone: mobile_phone,
        email       : email,
      }

      this.setState({
        number_error         : false,
        name_error           : false,
        same_passwords_error : false,
        email_error          : false,
        password_length_error: false,
        email_error_content  : 'Введіть коректний e-mail',

      })
      let error = false

      if (log.length === 0) {
        this.setState({ name_error: true })
        error = true
      }
      if (email.length === 0 || email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) === null) {
        this.setState({ email_error: true })
        error = true
      }
      if (mobile_phone.match(/^\+?1?\d{9,15}$/g) === null) {
        this.setState({ number_error: true })
        error = true
      }
      if (pass_match !== pass || pass.length === 0) {
        this.setState({ same_passwords_error: true })
        error = true
      }
      if (pass_match === pass && pass.length < 5) {
        this.setState({ password_length_error: true })
        error = true
      }

      if (error === false) {
        server.post('/api/posts/register', info).then(res => {
          if (res.status === 201) this.props.dispatch(logIn(email, pass))

          return 'success'
        })
          .catch((_) => {
            this.setState({ email_error: true })
            this.setState({ email_error_content: 'Такий email вже зареєстрований' })
            this.setState({ upw_is_sending: false })

            return 'error'
          })
        this.setState({ valid: true })
      } else {
        this.setState({ upw_is_sending: false })
      }
    }

    validate_empty (data) {

    }

    isLogged () {
      const data = this.cookies.get('Token')

      if (jwt.decode(data) === null) {
        return false
      }

      if (data === '' || data === 'undefined' || data === undefined) {
        return false
      }

      return true
    }

    get_data_about_user () {
      const date = this.cookies.get('Token')

      return jwt.decode(date).username
    }

    register (e) {
      this.setState({ upw_is_sending: true })
      e.preventDefault()
      this.registrate_user(this.refs.username.value, this.refs.password.value, this.refs.password_same.value, this.refs.email.value, this.refs.mobile_phone.value)
    }

    render () {
      return (
        <div style={{ width: '100%' }}>
          {this.props.logged_in
                  ? (() => {
                      this.props.history.push('/auth')
                      window.location.reload()
                    })()
                  : ''}
          <div id='authorization_form'>

            <div className='authorization_image_block'>
              <div className='inner_image_auth'>

              </div>
              {this.isLogged()
                          ? (
                            <div className='media_text_block_inner'>

                              <span
                                className='data_inner_media_auth'
                                style={{
                                        float   : 'left',
                                        position: 'relative',
                                }}
                              >
                                <span
                                  className='slesh'
                                  style={{
                                        position: 'absolute',
                                        left    : '-30px',
                                        top     : '0',
                                  }}
                                >
                                  /
                                </span>
                                Доброго дня
                                <br />
                                {this.get_data_about_user()}

                              </span>

                            </div>
)
                          : (
                            <div className='media_text_block_inner'>

                              <span
                                className='data_inner_media_auth'
                                style={{
                                        float   : 'left',
                                        position: 'relative',
                                }}
                              >
                                <span
                                  className='slesh'
                                  style={{
                                        position: 'absolute',
                                        left    : '-30px',
                                        top     : '0',
                                  }}
                                >
                                  /
                                </span>
                                Зареєструватися
                                <br />
                                на сайті,

                              </span>
                              <span
                                className='reason_inner'
                                style={{
                                        position: 'absolute',
                                        left    : '0',
                                }}
                              >
                                щоб потрапити до Особистого кабінету
                              </span>

                            </div>
)}
            </div>
            <div className='overflow_move'>
              <div
                className='form_block' style={{
                          height: '1000px',
                          width : '100%',
                }}
              >
                <div className='form_section_body'>

                  <form>
                    <div id='anketa_top_fixed'>
                      Щоб заповнити анкету учасника,
                      <br />
                      <span
                        className='login_style_backgorund'
                      >
                        зареєструйтеся
                      </span>
                      {' '}
                      або
                      {' '}
                      <a
                        href='login'
                        className='link_register_dashed'
                      >
                        увійдіть
                      </a>

                    </div>
                    <div id='inputs_area'>
                      <div className='auth_area_default'>
                        <input
                          ref='username'
                          className='animation_input_auth'
                          type='text'
                          name='username'
                          required
                          onChange={(e) => this.setState({ upw_name: e.target.value })}
                        />
                        <label className='form__label'>
                          Ім&apos;я
                          та прізвище:
                        </label>
                        {this.state.name_error
                                              ? (
                                                <div
                                                  className='errorField
                                                '
                                                >

                                                  Введіть ім&apos;я та прізвище

                                                </div>
)
                                              : null}
                      </div>
                      <div
                        className='auth_area_default'
                      >
                        <input
                          ref='email'
                          className='animation_input_auth'
                          type='text'
                          name='text'
                          required
                          onChange={(e) => this.setState({ upw_mail: e.target.value })}
                        />
                        <label className='form__label'>E-mail:</label>
                        {this.state.email_error
                                              ? (
                                                <div
                                                  className='errorField
                                            '
                                                >
                                                  {' '}
                                                  {this.state.email_error_content}

                                                </div>
)
                                              : null}
                      </div>
                      <div
                        className='auth_area_default'
                      >
                        <input
                          ref='mobile_phone'
                          className='animation_input_auth'
                          type='text'
                          name='text'
                          required
                          onChange={(e) => this.setState({ upw_phone: e.target.value })}
                        />
                        <label className='form__label'>
                          Номер
                          телефону:
                        </label>
                        {this.state.number_error
                                              ? (
                                                <div
                                                  className='errorField
                                            '
                                                >
                                                  Введіть коректний телефон
                                                  (+380987654321)
                                                </div>
)
                                              : null}
                      </div>
                      <div
                        className='auth_area_default'
                      >
                        <input
                          ref='password'
                          className='animation_input_auth'
                          type='password'
                          name='password'
                          required
                          onChange={(e) => this.setState({ upw_pass: e.target.value })}
                        />
                        <label className='form__label'>Пароль:</label>
                        {this.state.same_passwords_error
                                              ? (
                                                <div
                                                  className='errorField
                                            '
                                                >
                                                  Введені паролі не
                                                  співпадають
                                                </div>
)
                                              : null}
                        {this.state.password_length_error
                                              ? (
                                                <div
                                                  className='errorField
                                            '
                                                >
                                                  Пароль занадто короткий ( $
                                                  {'>'}
                                                  {' '}
                                                  5 символів )
                                                </div>
)
                                              : null}

                      </div>
                      <div
                        className='auth_area_default'
                      >
                        <input
                          ref='password_same'
                          className='animation_input_auth'
                          type='password'
                          name='text'
                          required
                          onChange={(e) => this.setState({ upw_pass_repeat: e.target.value })}
                        />
                        <label className='form__label'>
                          Підтвердити
                          пароль:
                        </label>
                        {this.state.same_passwords_error
                                              ? (
                                                <div
                                                  className='errorField
                                            '
                                                >
                                                  Введені паролі не
                                                  співпадають
                                                </div>
)
                                              : null}
                      </div>
                      <button
                        className={`upw_button upw_button_form${(this.state.upw_name && this.state.upw_mail && this.state.upw_phone && this.state.upw_pass && this.state.upw_pass_repeat) ? '' : ' upw_button_disabled'}${this.state.upw_is_sending ? ' upw_button_disabled' : ''}`}
                        data-busy-label='Зачекайте будь ласка ...'
                        onClick={this.register}
                      >
                        {this.state.upw_is_sending ? 'Іде надсилання...' : 'Зареєструватись'}
                      </button>

                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
}

function mapStateToProps (state) {
  return {
    user     : state.user,
    user_inf : state.user_info,
    fetched  : state.fetched,
    logged_in: state.logged_in,
  }
}

export default connect(mapStateToProps)(withRouter(RegistrationWidget))
