import { useEffect, useState } from 'react'
import server from 'utils/api/server'

import TextField from 'components/form_elements/TextField'

// type NominationProps = {
//     texts: object,
//     rest_api_url: string,
//     status: number,
//     submition_url: string,
//     next_stage: boolean
// }

const Nomination = ({ texts, submition_url, status, rest_api_url, next_stage }) => {
  const [input, setInput] = useState('')
  const [buttonText, setButtonText] = useState('Надіслати')
  const [isSending, setIsSending] = useState(false)
  const [nominationData] = useState(texts)
  const allow_submition = status === 5 && next_stage

  useEffect(() => {
    if (submition_url) {
      setButtonText('Надіслати повторно')
    }
    setInput(submition_url)
  }, [submition_url, next_stage])

  const callback_input = (info) => setInput(info)
  const send_input_information = () => {
    setIsSending(true)
    setButtonText('Іде Надсилання ...')
    const url = rest_api_url

    server.post(url, { url: input }).then((_) => {
      setButtonText('Успішно надіслано')
    })
  }
  const result_submition = () => {
    return (
      <>
        <TextField
          ParentCallback={callback_input}
          prefill={{ text: submition_url }}
          info={{ Title: 'Посилання на рішення:' }}
        />
        <br />
        <div style={{ textAlign: 'center' }}>
          <div className={isSending ? 'upw_button upw_button_disabled' : 'upw_button'} onClick={send_input_information}>
            {buttonText}
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      {status ? nominationData[status] : null}
      {allow_submition ? result_submition() : null}
    </>
  )
}

export default Nomination
