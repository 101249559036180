import { Component } from 'react'


class QaTable extends Component {
  render () {
    const data = [
      [
        1062,
        1657,
        2258,
        4859,
        6485,
        7316,
        7424,
        7478,
        7481,
        7491,
        7495,
        708,
        4090,
        4442,
        7427,
        7473,
      ],
      ['Galina Krivosheyeva',
        'Valery Bulakh',
        'Ярослава Багрiй',
        'Вікторія Ліхоткіна',
        'Natalie Datsyuk',
        'Анна Колесніченко',
        'Vlad  Trescheyko',
        'Безвугляк Артем',
        'Марина Шульга',
        'Kateryna Viskunova',
        'Тарас Авраменко',
        'Денис Клопышко',
        'Maksim Pryvolnev',
        'Anna Vasylenko',
        'Владислав Трещейко',
        'Artem Bo',
      ],
      [...(new Array(11).fill(0)
        .map(_ => 'QC')), ...(new Array(5).fill(0)
        .map(_ => 'QA'))],
      [215, 193, '-', '-', 276, 43, '-', 303, 271, 200, 222, '-', 103, 101, 81, '-'],
      [
        '106.1',
        '270.7',
        '157.9',
        '120.2',
        '202.6',
        '92.8',
        '138.2',
        '242.3',
        '178.5',
        '255.6',
        '253.0',
        '80.25',
        '90',
        '94.25',
        '101.25',
        '116.25',
      ],
    ]

    return (
      <div className='custom-table'>
        <div className='custom-table-description'>
          ФІНАЛІСТИ DEV CHALLENGE QA
        </div>
        <table>
          <thead>
            <tr>
              <th rowSpan='2'>ID</th>
              <th rowSpan='2'>Name</th>
              <th rowSpan='2'>Номінація</th>
              <th rowSpan='2'>Бали Кваліфікація</th>
              <th rowSpan='2'>Бали Онлайн Раунд</th>
              <th rowSpan='2'>Бали Фінал</th>
              <th rowSpan='2'>Бали загалом</th>
            </tr>
          </thead>
          <tbody>
            {data[0].map((Item, Index) => {
                          return (
                            <tr>
                              <td>{data[0][Index]}</td>
                              <td>{data[1][Index]}</td>
                              <td>{data[2][Index]}</td>
                              <td>64</td>
                              <td>{data[4][Index]}</td>
                              <td>{data[3][Index]}</td>
                              <td>{parseFloat(data[4][Index]) + ((data[3][Index] === '-') ? 0 : data[3][Index]) + 64}</td>
                            </tr>
                          )
                        })}
          </tbody>
        </table>
      </div>

    )
  }
}

export default QaTable
