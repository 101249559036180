import { Component } from 'react'


class BannerWidget extends Component {
  collect_images () {
    const arr = []

    this.props.item.block_data.forEach((obj) => {
      arr.push(obj.image.file_image_svg)
    })

    return arr
  }

  constructor (props) {
    super(props)
    this.state = {
      images : this.collect_images(),
      counter: 0,
    }
  }

  render () {
    return (
      <div className='banner' style={{ backgroundImage: `url(${this.state.images[this.state.counter % this.state.images.length]})` }}>

      </div>
    )
  }

  componentDidMount () {
    this.interval = setInterval(() => {
      this.setState({
        counter: this.state.counter + 1,
      })

      return 0
    }, 7000)
  }

  componentWillUnmount () {
    clearInterval(this.interval)
  }
}

export default BannerWidget
