import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import server from 'utils/api/server'

import { getCurrentStage, getCurrentStageForSubmition } from './helpers'
import SingleNomination from './SingleNomination'
import { team_texts } from './nomination_texts/texts_team'
import { devops_final_round_texts, devops_online_round_texts } from './nomination_texts/texts_devops'
import { qa_final_round_texts, qa_online_round_texts } from './nomination_texts/texts_qa'
import { backend_final_round_texts, backend_online_round_texts } from './nomination_texts/texts_backend'
import { frontend_code_final_round_texts, frontend_code_online_round_texts } from './nomination_texts/texts_frontend_code'
import { frontend_layout_final_round_texts, frontend_layout_online_round_texts } from './nomination_texts/texts_frontend_layout'
import { app_design_final_round_texts, app_design_online_round_texts } from './nomination_texts/texts_app_design'
import { app_product_online_round_texts, app_product_final_round_texts } from './nomination_texts/texts_app_product'


class GetCurrentTournament extends Component {
  constructor (props) {
    super(props)
    this.state = { form: false, scren: false }
    this.next_stage = getCurrentStage()
    this.allow_submition = getCurrentStageForSubmition()
  }

  componentDidMount () {
    this.getDevchXVIIInfo()
  }

        getDevchXVIIInfo = async () => {
          const url = '/api/posts/form/devch_xvii_list'
          const result = await server.get(url)

          this.setState({ form: result.data, status: result.status })
        }

        getNominationTextOnDate = (old_text, updated_text) => {
          return this.next_stage ? updated_text : old_text
        }

        onDesignAppClick = () => {
          const app_design_texts = this.getNominationTextOnDate(app_design_online_round_texts, app_design_final_round_texts)
          const current_nomination = (
            <SingleNomination
              texts={app_design_texts}
              rest_api_url={'/posts/form/devch_xvii_design_app_final_round'}
              next_stage={this.allow_submition}
              submition_url={this.props.user_info.devchallenge_xvii_design_app_final_round_submition}
              status={this.props.user_info.devchallenge_xvii_design_app_status}
            />
          )

          this.setState({ scren: current_nomination })
        }

        onDesignProductClick = () => {
          const app_product_texts = this.getNominationTextOnDate(app_product_online_round_texts, app_product_final_round_texts)
          const current_nomination = (
            <SingleNomination
              texts={app_product_texts}
              rest_api_url={'/posts/form/devch_xvii_design_product_final_round'}
              next_stage={this.allow_submition}
              submition_url={this.props.user_info.devchallenge_xvii_design_product_final_round_submition}
              status={this.props.user_info.devchallenge_xvii_design_product_status}
            />
          )

          this.setState({ scren: current_nomination })
        }

        onBackendClick = () => {
          const backend_texts = this.getNominationTextOnDate(backend_online_round_texts, backend_final_round_texts)
          const current_nomination = (
            <SingleNomination
              texts={backend_texts}
              rest_api_url={'/posts/form/devch_xvii_backend_final_round'}
              next_stage={this.allow_submition}
              submition_url={this.props.user_info.devchallenge_xvii_backend_final_round_submition}
              status={this.props.user_info.devchallenge_xvii_backend_status}
            />
          )

          this.setState({ scren: current_nomination })
        }

        onFrontendLayoutdClick = () => {
          const frontend_layout_texts = this.getNominationTextOnDate(frontend_layout_online_round_texts, frontend_layout_final_round_texts)
          const current_nomination = (
            <SingleNomination
              texts={frontend_layout_texts}
              rest_api_url={'/posts/form/devch_xvii_frontend_layout_final_round'}
              next_stage={this.allow_submition}
              submition_url={this.props.user_info.devchallenge_xvii_frontend_layout_final_round_submition}
              status={this.props.user_info.devchallenge_xvii_frontend_layout_status}
            />
          )

          this.setState({ scren: current_nomination })
        }

        onFrontendCodeClick = () => {
          const frontend_code_texts = this.getNominationTextOnDate(frontend_code_online_round_texts, frontend_code_final_round_texts)

          const current_nomination = (
            <SingleNomination
              texts={frontend_code_texts}
              rest_api_url={'/posts/form/devch_xvii_frontend_code_final_round'}
              next_stage={this.allow_submition}
              submition_url={this.props.user_info.devchallenge_xvii_frontend_code_final_round_submition}
              status={this.props.user_info.devchallenge_xvii_frontend_code_status}
            />
          )

          this.setState({ scren: current_nomination })
        }

        onQaClick = () => {
          const qa_texts = this.getNominationTextOnDate(qa_online_round_texts, qa_final_round_texts)
          const current_nomination = (
            <SingleNomination
              texts={qa_texts}
              rest_api_url={'/posts/form/devch_xvii_qa_qc_final_round'}
              next_stage={this.allow_submition}
              submition_url={this.props.user_info.devchallenge_xvii_qa_qc_final_round_submition}
              status={this.props.user_info.devchallenge_xvii_qa_qc_status}
            />
          )

          this.setState({ scren: current_nomination })
        }

        onDevOpsClick = () => {
          const devops_texts = this.getNominationTextOnDate(devops_online_round_texts, devops_final_round_texts)
          const current_nomination = (
            <SingleNomination
              rest_api_url={'/posts/form/devch_xvii_devops_final_round'}
              texts={devops_texts}
              next_stage={this.allow_submition}
              submition_url={this.props.user_info.devchallenge_xvii_devops_final_round_submition}
              status={this.props.user_info.devchallenge_xvii_devops_status}
            />
          )

          this.setState({ scren: current_nomination })
        }

        onTeamClick = () => {
          const current_nomination = (
            <SingleNomination
              rest_api_url={'/posts/form/devch_xvii_team'}
              texts={team_texts}
              submition_url={this.props.user_info.devchallenge_xvii_team_submition}
              status={3}
              next_stage
            />
          )

          this.setState({ scren: current_nomination })
        }

        // onBigDataClick = () => {
        //   // ...
        // }

        getChoices = () => {
          const is_qualification_started = false // TODO Should be recieved from the server
          const nominations = [
            {
              title   : 'Product Design (UX)',
              isActive: this.state.form.product_design,
              onClick : this.onDesignProductClick,
            },
            {
              title   : 'App Design (UI)',
              isActive: this.state.form.app_design,
              onClick : this.onDesignAppClick,
            },
            {
              title   : 'Backend',
              isActive: this.state.form.backend,
              onClick : this.onBackendClick,
            },
            {
              title   : 'Frontend layout',
              isActive: this.state.form.frontend_layout,
              onClick : this.onFrontendLayoutdClick,
            },
            {
              title   : 'Frontend code',
              isActive: this.state.form.frontend_code,
              onClick : this.onFrontendCodeClick,
            },
            {
              title   : 'Manual QA',
              isActive: this.state.form.qa_qs,
              onClick : this.onQaClick,
            },
            {
              title   : 'DevOps',
              isActive: this.state.form.devops,
              onClick : this.onDevOpsClick,
            },
            // {
            //   title   : 'BigData',
            //   isActive: this.state.form.big_data,
            //   onClick : this.onBigDataClick,
            // },
          ]

          const choices = (
            <ul style={{ 'margin-left': '1.75em' }}>
              {nominations.map((nomination) => (
                <li key={nomination.title}>
                  {
                    nomination.isActive
                      ? (
                        is_qualification_started
                          ? <b className='nomination_active' onClick={nomination.onClick}>{nomination.title}</b>
                          : <b>{nomination.title}</b>
                      )
                      : <span>{nomination.title}</span>
                  }
                </li>
              ))}
            </ul>
          )

          return choices
        }

        getTeamChoices = () => {
          const choices = (
            <ul style={{ 'margin-left': '1.75em' }}>
              <li>{this.props.user_info.team_form ? <b className='nomination_active' onClick={this.onTeamClick}> Team </b> : <a href='/team'>Team</a>}</li>
            </ul>
          )

          return choices
        }

        render () {
          const text_before_picking_nominations = (
            <>
              <p>Бажаєте взяти участь у DEV Challenge XVIII?</p>
              <br />
              <p>Наразі доступна реєстрація на такі номінаціі:</p>
              <br />
              {this.getChoices()}
              <br />
              <p>
                Ми намагаємось створювати якомога меньше обмежень, тому у цьому сезоні можна взяти участь у декількох змаганнях одночасно.
              </p>
              <br />
              <p>
                Цьогорічний сезон має оновлення. Зі всіма оновленнями можна ознайомитися в
                {' '}
                <a target='_blank' rel='noopener noreferrer' href='http://docs.google.com/document/d/1hJ1RgRWRBkV6f4975QywkHhgNzoanUaUi-xzYWTOQcE/edit#'>Туторіалі</a>
                {' '}
                учасника.
              </p>
              <br />
              <br />
              <div style={{ textAlign: 'center' }}>
                <a href='/application' className='upw_button '>
                  Обрати номінації!
                </a>
              </div>
            </>
          )

          const text_after_picking_nominations = (
            <>
              <p>Вітаємо! Ви успішно зареєструвалися на DEV Challenge XVIII в наступних номінаціях:</p>
              <br />
              {this.getChoices()}
              <br />
              <p>
                Наступний крок — виконання завдання Етапу Кваліфікація. Доступ буде відкрито 01.11.
              </p>
              <br />
              <p>
                Для зв‘язку з організаторами, суддями та іншими учасниками — додавайтеся до
                {' '}
                <a target='_blank' rel='noopener noreferrer' href='https://join.slack.com/t/devchallengehq/shared_invite/zt-hg42q544-mIvjh5etnZuVfv8pvB_1qg'>Slack Workspace</a>
                . Також нагадуємо, що цей сезон Чемпіонату має кілька оновлень. З ними можна ознайомитися у
                {' '}
                <a target='_blank' rel='noopener noreferrer' href='http://docs.google.com/document/d/1hJ1RgRWRBkV6f4975QywkHhgNzoanUaUi-xzYWTOQcE/edit#'>Туторіалі</a>
                {' '}
                учасника.
              </p>
              <br />
              <br />
              <div style={{ textAlign: 'center' }}>
                <a href='/application' className='upw_button '>
                  Змінити номінації!
                </a>
              </div>
            </>
          )

          const is_any_nomination_chosen = this.state.form.status !== 209
          const is_opened_other_screen = Boolean(this.state.scren)
          const screen = this.state.scren

          return (
            is_opened_other_screen
              ? (
                <>
                  {screen}
                  <div style={{ textAlign: 'center' }}>
                    <div className='upw_button ' onClick={() => this.setState({ scren: false })}>Повернутись назад</div>
                  </div>
                </>
              )
              : (is_any_nomination_chosen
                ? text_after_picking_nominations
                : text_before_picking_nominations
              )
          )
        }
}

function mapStateToProps (state) {
  return {
    user        : state.user,
    user_info   : state.user_info,
    fetched     : state.fetched,
    fetched_form: state.fetched_form,
    form_info   : state.form_info,
  }
}

export default connect(mapStateToProps)(withRouter(GetCurrentTournament))
