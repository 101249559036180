import { Component } from 'react'
import server from 'utils/api/server'

import { ReactComponent as RocketIcon } from 'assets/rocket.svg'
import { ReactComponent as RestoreIcon } from 'assets/restore.svg'


class TextField extends Component {
  constructor (props) {
    super(props)

    this._onTouchDown = this._onTouchDown.bind(this)

    this.render = this.render.bind(this)

    this.state = {
      text      : undefined,
      text_field: false,

    }
  };

  _onTouchDown (e) {
    if (this.text_field.contains(e.target)) {
      this.setState(
        { text_field: true },
      )
    } else {
      this.setState(
        { text_field: false },
      )
    }
  };

  componentDidMount () {
    document.addEventListener('touchstart', this._onTouchDown, false)
    document.addEventListener('mousedown', this._onTouchDown, false)
  };

  componentWillUnmount () {
    document.removeEventListener('touchstart', this._onTouchDown, false)
    document.removeEventListener('mousedown', this._onTouchDown, false)
  }

  render () {
    return (
      <div>
        <div className='upw_form_text_field'>
          <div
            ref={text_field => { this.text_field = text_field }}
            className={`upw_choice_button${this.state.text_field ? ' upw_choice_active' : ''}`}
          >
            <div
              className={`upw_text_field_label${(this.state.text_field || this.state.text) ? ' upw_text_field_label_active' : ''}`}
            >
              {this.props.info.Title}
            </div>
            <input
              className='upw_text_field' onChange={(e) => {
                          this.setState({ text: e.target.value })
                          this.props.ParentCallback(e.target.value)
              }}
            />

          </div>
        </div>
      </div>

    )
  }
}

class RestoreWidget extends Component {
  constructor (props) {
    super(props)
    this.restore_password = this.restore_password.bind(this)
    this.state = {
      confirmed  : '',
      error_email: false,
      sending    : false,
    }
  }

  restore_password () {
    this.setState({
      error_email: false,
      sending    : true,

    })
    server.post('/rest-auth/password/reset/', {
      email: this.state.res0,
    }).then((response) => {
      response.status === 200
        ? this.setState({ confirmed: true })
        : this.setState({
          error_email: true,
          sending    : false,
        })
    })
      .catch((_) => {
        this.setState({
          error_email: true,
          sending    : false,
        })
      })
  }

    Callback0 = (data) => {
      this.setState({ res0: data })
    };

    render () {
      return (
        <div className='user_page_widget'>
          <div className='upw_left_panel'>
            <div
              className='upw_panel_image'
              style={{ backgroundImage: 'url(\'/media/local/FormPicture.jpg\')' }}
            />
          </div>
          <div className='upw_right_panel'>
            <div className='upw_control_panel' />
            <div className='upw_content'>

              <div
                className='upw_inner_content' style={{
                          textAlign: 'center',
                          display  : this.state.confirmed ? 'block' : 'none',
                }}
              >
                <RocketIcon className='upw_rocket' />
                <p className='upw_rocket_text'>
                  Вітаємо,
                  <br />
                  форма успішно відправлена!
                </p>
              </div>
              <div
                className='upw_inner_content'
                style={{ display: !this.state.confirmed ? 'block' : 'none' }}
              >

                <div className='upw_form_title'>
                  <b>Забув пароль?</b>
                  <br />
                  Вкажiть e-mail, який був вказаний при
                  реєстрації
                  і ми відправимо інструкцію з встановлення.
                </div>
                {this.state.confirmed
                              ? (
                                <h2>
                                  Інструкції
                                  Було Успішно Відправлено!
                                </h2>
)
                              : (
                                <div
                                  className='upw_form_field'
                                  style={{ marginTop: '80px' }}
                                >
                                  <TextField
                                    ParentCallback={this.Callback0}
                                    info={{ Title: 'E-mail:' }}
                                  />
                                  {this.state.error_email
                                      ? (
                                        <small>
                                          email does not
                                          exist
                                        </small>
)
                                      : null}
                                </div>
)}
                <div className='upw_form_field'>
                  <div
                    className={`upw_button upw_button_form${(this.state.res0 && !this.state.sending) ? '' : ' upw_button_disabled'}`}
                    onClick={this.restore_password}
                  >
                    Надіслати
                  </div>
                </div>
                <div className='upw_form_field'>
                  <div className='upw_restoring_logo'>
                    <RestoreIcon className='upw_form_logo_svg' />
                    <div
                      className='upw_form_title'
                      style={{ marginTop: '10px' }}
                    >
                      Забули і e-mail?
                      <br />
                      Тоді звертайтеся в
                      {' '}
                      <a
                        className='upw_link_dotted'
                        href='/feedback'
                      >
                        наш
                        саппорт
                      </a>
                      .
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      )
    }
}

export default RestoreWidget
