import { Component } from 'react'
import BlockWithTabsWidget from './widgets/BlockWithTabsWidget'
import BigImageWidget from './widgets/BigImageWidget'
import BannerWidget from './widgets/BannerWidget'
import SliderMoveWidget from './widgets/SliderMoveWidget'
import CartsWidget from './widgets/CartsWidget'
import HistoryOfSuccessWidget from './widgets/HistoryOfSuccessWidget'
import BlockWithTextWidget from './widgets/BlockWithTextWidget'
import MiniTimeline from './widgets/MiniTimelineWidget'
import FooterWidget from './widgets/FooterWidget'
import SliderPhotoWidget from './widgets/SliderPhotoWidget'
import RegistrationWidget from './widgets/RegistrationWidget'
import LoginWidget from './widgets/LoginWidget'
import NavBarWidget from './widgets/NavBarWidget'
import ProfileWidget from './widgets/NewProfile'
import CustomWidget from './widgets/CustomWidget'
import MiniTextWidget from './widgets/MiniTextWidget'
import YouTubeWidget from './widgets/YouTubeWidget'
import TextButtonWidget from './widgets/TextButtonWidget'
import TableWidget from './widgets/TableWidget'
import RestoreWidget from './widgets/RestoreWidget'
import ConnectionWidget from './widgets/ConnectionWidget'
import TableManager from './tables/TableManager'
import DesignForm from './forms/DesignForm'
import DevchXVIIForm from './forms/DevchXVIIForm'
import FormWidget from './widgets/FormWidget'
import TeamFormXVII from './forms/TeamFormXVII'


class CaseSwitcher extends Component {
  render () {
    return (

      <div className='row'>
        {(this.props.item.map((ITEM, Index) => {
                  switch (ITEM.widget_name) {
                    case 'BlockWithTabsWidget':
                      return <BlockWithTabsWidget item={ITEM} />

                    case 'TextButtonWidget':
                      return <TextButtonWidget item={ITEM} />

                    case 'BigImageWidget':
                      return <BigImageWidget current_page={this.props.current_page} item={ITEM} />

                    case 'BannerWidget':
                      return <BannerWidget item={ITEM} />

                    case 'SliderMoveWidget':
                      return <SliderMoveWidget item={ITEM} />

                    case 'CartsWidget':
                      return <CartsWidget item={ITEM} />

                    case 'StoryOfGreatnessWidget':
                      return <HistoryOfSuccessWidget item={ITEM} />

                    case 'FooterWidget':
                      return <FooterWidget current_page={this.props.current_page} item={ITEM} />

                    case 'ProfileWidget':
                      return <ProfileWidget data_from_server={ITEM} />

                    case 'SliderPhotoVideoWidget':
                      return <SliderPhotoWidget item={ITEM} />

                    case 'MiniTextBlockWidget':
                      return <MiniTextWidget item={ITEM} />

                    case 'TimeLineWidget':
                      return <MiniTimeline item={ITEM} />

                    case 'TableWidget':
                      return <TableWidget item={ITEM} />

                    case 'FinalsTable':
                      return <TableManager item={ITEM} />

                    case 'RestoreWidget':
                      return <RestoreWidget item={ITEM} />

                    case 'ConnectionWidget':
                      return <ConnectionWidget item={ITEM} user_data={this.props.user_data} />

                    case 'ModifiedBlockWithTabsWidget':
                      return (
                        <BlockWithTextWidget
                          item={ITEM}
                        />
)

                    case 'NavBarWidget':
                      return (
                        <NavBarWidget
                          current_page={this.props.current_page}
                          user_data={this.props.user_data}
                          item={ITEM}
                        />
)

                    case 'LoginWidget':
                      return <LoginWidget item={ITEM} user_data={this.props.user_data} />

                    case 'RegistrationWidget':
                      return <RegistrationWidget item={ITEM} />

                    case 'CustomWidget':
                      return <CustomWidget item={ITEM} />

                    case 'YouTubeWidget':
                      return <YouTubeWidget item={ITEM} />

                    case 'FormWidget':
                      return <FormWidget item={ITEM} />

                    case 'DesignFormWidget':
                      return <DesignForm item={ITEM} />

                    case 'DevchallengeSeventeenFormWidget':
                      return <DevchXVIIForm item={ITEM} />

                    case 'DevchallengeSeventeenTeamFormWidget':
                      return <TeamFormXVII item={ITEM} />

                    default:
                      return ' '
                  }
                }))}
      </div>
    )
  }
}

export default CaseSwitcher
