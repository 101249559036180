import { Component } from 'react'

import { ReactComponent as QuoteOpenIcon } from 'assets/quote-open.svg'
import { ReactComponent as QuoteCloseIcon } from 'assets/quote-close.svg'


class HistoryOfSuccessWidget extends Component {
  collect_data () {
    const info = []

    this.props.item.block_data.map((data) => {
      info.push({
        Image          : data.image.file_image_svg,
        Name           : data.name,
        Description    : data.about,
        DateTime       : data.date,
        LongDescription: data.text,
      })

      return 0
    })

    return info
  }

  constructor (props) {
    super(props)
    this.render = this.render.bind(this)
    this.switcher = this.switcher.bind(this)
    this.buttonGenerator = this.buttonGenerator.bind(this)
    this.listGenerator = this.listGenerator.bind(this)

    this._onMouseMove = this._onMouseMove.bind(this)
    this._onMouseUp = this._onMouseUp.bind(this)
    this._onMouseDown = this._onMouseDown.bind(this)
    this.info = this.collect_data()

    this.state = {
      current_item: 0,

      CurrentActive: 1,
      buttonsState : this.listGenerator(0),

      FirstOpacity         : '1',
      FirstImage           : this.info[0].Image,
      FirstName            : this.info[0].Name,
      FirstDescription     : this.info[0].Description,
      FirstDateTime        : this.info[0].DateTime,
      FirstLongDescription : this.info[0].LongDescription,
      SecondOpacity        : '0',
      SecondImage          : '',
      SecondName           : '',
      SecondDescription    : '',
      SecondDateTime       : '',
      SecondLongDescription: '',
    }
  };

  listGenerator (num) {
    const b = []

    for (let i = 0; i < this.info.length; i++) {
      b.push('')
    }
    b[num] = 'history_active'

    return b
  }

  _onMouseMove (e) {
    this.setState({ x: e.touches[0].pageX })
  }

  _onMouseDown (e) {
    this.setState({ origin_x: e.touches[0].pageX, pushed: true })
  }

  _onMouseUp () {
    // Swipe realisation
    if (this.state.pushed) {
      if (this.state.x - this.state.origin_x < 0) {
        this.setState({ current_item: this.state.current_item + 1 })
      } else if (this.state.x - this.state.origin_x > 0.5) {
        this.setState({ current_item: this.state.current_item - 1 })
      }

      this.switcher(((this.state.current_item % this.info.length) + this.info.length) % this.info.length)

      this.setState({ pushed: false })
    }
  }

  switcher (num) {
    this.setState({
      buttonsState: this.listGenerator(num),
      current_item: num,
    })

    if (this.state.CurrentActive === 1) {
      this.setState({
        CurrentActive: 0,

        FirstOpacity : '0',
        SecondOpacity: '1',

        SecondImage          : this.info[num].Image,
        SecondName           : this.info[num].Name,
        SecondDescription    : this.info[num].Description,
        SecondDateTime       : this.info[num].DateTime,
        SecondLongDescription: this.info[num].LongDescription,
      })
    } else {
      this.setState({
        CurrentActive: 1,

        FirstOpacity : '1',
        SecondOpacity: '0',

        FirstImage          : this.info[num].Image,
        FirstName           : this.info[num].Name,
        FirstDescription    : this.info[num].Description,
        FirstDateTime       : this.info[num].DateTime,
        FirstLongDescription: this.info[num].LongDescription,
      })
    }
  }

  buttonGenerator (num) {
    return (
      <li
        key={num}
        className={this.state.buttonsState[num]} onClick={() => this.switcher(num)}
      >
        <button className='animate_line'>

        </button>
      </li>
    )
  };

  render () {
    return (
      <div
        className='story_of_succes_widget'
        onTouchMove={this._onMouseMove}
        onTouchStart={this._onMouseDown} onTouchEnd={this._onMouseUp}
        onTouchCancel={this._onMouseUp}
      >

        <div
          className='left_card'
          style={{ opacity: this.state.FirstOpacity }}
        >
          <img
            src={this.state.FirstImage}
            alt={this.state.FirstName}
          />
          <div className='description'>
            <div
              className='title_history'
            >
              {this.state.FirstName}
            </div>
            <div
              className='subtitle'
            >
              {this.state.FirstDescription}
            </div>
          </div>
        </div>
        <div
          className='left_card'
          style={{ opacity: this.state.SecondOpacity }}
        >
          <img
            src={this.state.SecondImage}
            alt={this.state.SecondName}
          />
          <div className='description'>
            <div
              className='title_history'
            >
              {this.state.SecondName}
            </div>
            <div
              className='subtitle'
            >
              {this.state.SecondDescription}
            </div>
          </div>
        </div>
        <div
          className='date'
          style={{ opacity: this.state.FirstOpacity }}
        >
          <span>{this.state.FirstDateTime}</span>
        </div>
        <div
          className='date'
          style={{ opacity: this.state.SecondOpacity }}
        >
          <span>{this.state.SecondDateTime}</span>
        </div>
        <div
          className='right_card'
          style={{ opacity: this.state.FirstOpacity }}
        >
          <QuoteOpenIcon className='svg_history first' />
          <span className='card-long-text' dangerouslySetInnerHTML={{ __html: this.state.FirstLongDescription }} />
          <QuoteCloseIcon className='svg_history' />
        </div>
        <div
          className='right_card'
          style={{ opacity: this.state.SecondOpacity }}
        >
          <QuoteOpenIcon className='svg_history first' />
          <span className='card-long-text' dangerouslySetInnerHTML={{ __html: this.state.SecondLongDescription }} />
          <QuoteCloseIcon className='svg_history' />
        </div>
        <div className='control'>
          <ul className='animation_config'>

            {this.info.map((Data, id) => this.buttonGenerator(id))}

          </ul>
        </div>

      </div>

    )
  }
}

export default HistoryOfSuccessWidget
