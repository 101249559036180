import { Component } from 'react'


class TextField extends Component {
  constructor (props) {
    super(props)
    this._onTouchDown = this._onTouchDown.bind(this)
    this.render = this.render.bind(this)
    this.state = {
      text: this.props.prefill !== undefined ? this.props.prefill.text : undefined,
    }
    this.props.ParentCallback(this.props.prefill !== undefined ? this.props.prefill.text : undefined)
  };

  _onTouchDown (e) {
    if (this.text_field.contains(e.target)) {
      this.setState(
        { text_field: false },
      )
    } else {
      this.setState(
        { text_field: false },
      )
    }
  };

  componentDidMount () {
    document.addEventListener('touchstart', this._onTouchDown, false)
    document.addEventListener('mousedown', this._onTouchDown, false)
  };

  componentWillUnmount () {
    document.removeEventListener('touchstart', this._onTouchDown, false)
    document.removeEventListener('mousedown', this._onTouchDown, false)
  }

  render () {
    return (
      <div>
        <div className='upw_form_text_field'>
          <div
            ref={text_field => { this.text_field = text_field }}
            className={`upw_choice_button${this.state.text_field ? ' upw_choice_active' : ''}`}
          >
            <div
              className={`upw_text_field_label${(this.state.text_field || this.state.text !== undefined) ? ' upw_text_field_label_active' : ''}`}
            >
              {this.props.info.Title}
            </div>
            <input
              className='upw_text_field'
              value={this.state.text} onChange={(e) => {
                                 this.setState({ text: e.target.value })
                                 this.props.ParentCallback(e.target.value)
              }}
            />

          </div>
        </div>
      </div>

    )
  }
}

export default TextField
