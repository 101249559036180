import { Component } from 'react'
import { mediaURL } from 'global.js'


const images = [
  `${mediaURL}/media/media/1101_amadeus-01.svg`,
  `${mediaURL}/media/media/1101_DreamTeam-01.svg`,
  `${mediaURL}/media/media/1101_Valtech-01.svg`,
  `${mediaURL}/media/media/1101_WIX-01.svg`,
  `${mediaURL}/media/media/1101_Materialise-01.svg`,
]

const images_urls = [
  'http://www.amadeus.com/',
  'https://dreamteam.gg/',
  'https://www.valtech.com/',
  'https://www.wix.engineering/',
  'https://www.materialise.com/',
]

const margin = 0
const speed = 15

class SliderMoveWidget extends Component {
  OnHoverIn () {
    const animationName = 'animation_slider'

    this.setState({
      style: {
        animationName          : animationName,
        animationTimingFunction: 'linear',
        animationDuration      : `${speed}s`,
        animationDelay         : '0.0s',
        animationIterationCount: 'infinite',
        animationDirection     : 'normal',
        animationFillMode      : 'forwards',
        marginRight            : margin,
        animationPlayState     : 'paused',
      },
    })
  }

  OnHoverOut () {
    const animationName = 'animation_slider'

    this.setState({
      style: {
        animationName          : animationName,
        animationTimingFunction: 'linear',
        animationDuration      : `${speed}s`,
        animationDelay         : '0.0s',
        animationIterationCount: 'infinite',
        animationDirection     : 'normal',
        animationFillMode      : 'forwards',
        marginRight            : margin,
        animationPlayState     : 'running',
      },
    })
  }

  constructor (props) {
    super(props)
    this.width = (546 + margin) * images.length
    this.OnHoverIn = this.OnHoverIn.bind(this)
    this.OnHoverOut = this.OnHoverOut.bind(this)
    this.render = this.render.bind(this)
    this.code = []
    const animationName = 'animation_slider'

    this.state = {
      style: {
        animationName          : animationName,
        animationTimingFunction: 'linear',
        animationDuration      : `${speed}s`,
        animationDelay         : '0.0s',
        animationIterationCount: 'infinite',
        animationDirection     : 'normal',
        animationFillMode      : 'forwards',
        marginRight            : margin,
        animationPlayState     : 'running',
      },
    }
    // Style block
    const styleSheet = document.styleSheets[0]

    const keyframes =
    `@-webkit-keyframes ${animationName} {
        0% {margin-left: 0px;}
        100% {margin-left: -${this.width}px}
    }`

    styleSheet.insertRule(keyframes, styleSheet.cssRules.length)

    // Page Generator
    for (let i = 0; i < images.length - 1; i++) {
      this.code.push(<a href={images_urls[i + 1]} rel='noopener noreferrer' target='_blank'><img style={{ marginRight: margin }} src={images[i + 1]} alt='' onMouseEnter={this.OnHoverIn} onMouseLeave={this.OnHoverOut} /></a>)
    }
    for (let i = 0; i < images.length - 1; i++) {
      this.code.push(<a href={images_urls[i]} rel='noopener noreferrer' target='_blank'><img style={{ marginRight: margin }} src={images[i]} alt='' onMouseEnter={this.OnHoverIn} onMouseLeave={this.OnHoverOut} /></a>)
    }
  }

  render () {
    return (
      <div className='container-fluid widget_4'>
        <div className='photobanner'>
          <img className='first' style={this.state.style} src={images[0]} alt='' onMouseEnter={this.OnHoverIn} onMouseLeave={this.OnHoverOut} />
          {this.code}
        </div>
      </div>
    )
  }
}

export default SliderMoveWidget
