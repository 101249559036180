import { Component } from 'react'


class FrontendTable extends Component {
  render () {
    const data = [
      [
        131,
        191,
        406,
        637,
        691,
        734,
        956,
        1197,
        2303,
        2648,
        2938,
        3927,
        4405,
        4441,
        4562,
        4701,
        4736,
        5599,
        5747,
        5934,
        6012,
        7068,
        7156,
        7283,
        7287,
        7322,
      ],
      [

        'Павло Яновський',
        'Сергей',
        'Andrew Protsenko',
        'Віктор',
        'Катерина',
        'Алексей Циня',
        'Юлія Гаркуша',
        'Бойко Игорь',
        'Олексій Мірошник',
        'Stas Chernenko',
        'Степан Нарышков',
        'Надія Бондаренко',
        'Elena Makarenko',
        'Кукуріка Ірина',
        'Назар Устимчик',
        'Дмитро Гавриш',
        'Олександр Грушко',
        'Julia Karpenko',
        'Ярослав Хотченко',
        'Анастасія Трепитьон',
        'Екатерина Гайбор',
        'Serhii Yushchenko',
        'Сергій Бартків',
        'Єлизавета Бойко',
        'Олексій Гривюк',
        'Данило Нестеренко',

      ],
      [
        'Angular',
        'Angular',
        'React',
        'Vue',
        'Vue',
        'HTML CSS',
        'HTML CSS',
        'Vue',
        'Angular',
        'Angular',
        'HTML CSS',
        'HTML CSS',
        'Vue',
        'React',
        'HTML CSS',
        'Angular',
        'React',
        'React',
        'React',
        'HTML CSS',
        'HTML CSS',
        'HTML CSS',
        'React',
        'React',
        'Angular',
        'Angular',
      ],
      [
        156,
        0,
        440.6,
        174,
        0,
        418,
        430,
        524,
        569,
        606,
        565,
        522,
        252,
        424,
        613,
        238,
        534,
        102.6,
        278,
        301,
        493,
        453,
        449,
        454.6,
        108,
        380,
      ],
      [
        273,
        210,
        230,
        160,
        205,
        292,
        297,
        172,
        138,
        275,
        249,
        254,
        226,
        248,
        296,
        244,
        264,
        220,
        264,
        303,
        280,
        280,
        260,
        254,
        202,
        184,
      ],
    ]

    return (
      <div className='custom-table'>
        <div className='custom-table-description'>
          ФІНАЛІСТИ DEV CHALLENGE FRONTEND
        </div>
        <table>
          <thead>
            <tr>
              <th rowSpan='2'>ID</th>
              <th rowSpan='2'>Name</th>
              <th rowSpan='2'>Номінація</th>
              <th rowSpan='2'>Бали Кваліфікація</th>
              <th rowSpan='2'>Бали Онлайн Раунд</th>
              <th rowSpan='2'>Бали Фінал</th>
              <th rowSpan='2'>Бали загалом</th>
            </tr>
          </thead>
          <tbody>
            {data[0].map((Item, Index) => {
                          return (
                            <tr>
                              <td>{data[0][Index]}</td>
                              <td>{data[1][Index]}</td>
                              <td>{data[2][Index]}</td>
                              <td>64</td>
                              <td>{data[4][Index]}</td>
                              <td>
                                {data[3][Index]}
                                {' '}
                                {data[1][Index] === 'Алексей Циня' ? '*' : ''}
                              </td>
                              <td>{parseFloat(data[4][Index]) + ((data[3][Index] === '-' || data[1][Index] === 'Алексей Циня') ? 0 : data[3][Index]) + 64}</td>
                            </tr>
                          )
                        })}
          </tbody>
        </table>
      </div>

    )
  }
}

export default FrontendTable
