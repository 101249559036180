export const app_product_qualification_registered = (
  <>
    <p>
      Вітаємо! Ви успішно зареєструвалися на
      {' '}
      <b>DEV Challenge XVIII, номінація Product Design (UX).</b>
    </p>
    <br />
    <p>Наступний крок — виконання завдання Етапу Кваліфікація. Інформація у кабінеті буде оновлена після того, як будуть відомі результати Кваліфікації, протягом 1–2 робочих днів після проходження.</p>
    <br />
    <p>
      Для зв‘язку з організаторами, суддями та іншими учасниками — додавайтеся до
      {' '}
      <a rel='noopener noreferrer' href='https://join.slack.com/t/devchallengehq/shared_invite/zt-hg42q544-mIvjh5etnZuVfv8pvB_1qg' target='_blank'>Slack Workspace.</a>
    </p>
    <br />
    <p>
      Нагадуємо, що цей сезон Чемпіонату має кілька оновлень. З ними можна ознайомитися у
      {' '}
      <a href='https://docs.google.com/document/d/1hJ1RgRWRBkV6f4975QywkHhgNzoanUaUi-xzYWTOQcE/edit' target='_blank' rel='noopener noreferrer'>Туторіалі учасника.</a>
      {' '}
    </p>
    <br />
    <br />
    <div style={{ textAlign: 'center' }}>
      {' '}
      <a target='_blank' rel='noopener noreferrer' href='https://devchallenge19.typeform.com/to/yo06uVWL' className='upw_button '>Пройти Кваліфікацію</a>
    </div>
  </>
)

export const app_product_qualification_passed = (
  <>
    <p>/ Онлайн раунд</p>
    <br />
    <p>
      Онлайн Раунд розпочато. Завдання —
      <a target='_blank' href='https://docs.google.com/document/d/1c3t0qVyDQcdYlXIPAbvC557XSH-uCbCHIzvO8gEMmMY/edit#' rel='noopener noreferrer'>за посиланням</a>
      .
      {' '}
    </p>
    <br />
    <p>Пам’ятайте, що дедлайн завантаження рішення 18 жовтня. Результати будуть опубліковані 2-го листопада.</p>
    <br />
    <p>Уточнення щодо завдань можна задати у канал #nomination-product-ux-product.</p>
    <p>Питання організаційного плану у канал #02-ask-the-organisers.</p>
    <br />
    <p>Успіхів!</p>
    <br />
  </>
)

export const app_product_qualification_failed = (
  <>
    <p>
      На жаль, Кваліфікація не пройдена успішно.
      <br />
      Організатори не обмежують кількість спроб під час Кваліфікації. Ви можете спробувати ще раз. На жаль, через обмеження сервісу, доведеться це зробити з іншого e-mail та повідомити на hello@wechallenge.it Вашу скриньку.
    </p>
    <br />
    <p>
      Будемо раді бачити Вас наступного сезону.
      <a href='https://www.facebook.com/devchallengeua/' rel='noopener noreferrer'>Слідкуйте за новинами.</a>
      {' '}
    </p>
    <p>Успіхів!</p>
  </>
)

export const app_product_online_round_solution_not_received = (
  <>
    <p>/ Онлайн Раунд</p>
    <br />
    <p>На жаль, ми не отримали Вашого рішення завдання Онлайн Раунду. Нам дуже шкода, але на цьому Ваша участь у Чемпіонаті завершена. Ми будемо раді бачити Вас серед учасників DEV Challenge у наступних Чемпіонатах.</p>
    <br />
    <p>Якщо відбулася помилка, будь ласка, повідомте нам.</p>
    <br />
  </>
)

export const app_product_online_round_solution_received = (
  <>
    <p>/ Онлайн Раунд</p>
    <br />
    <p>Ми отримали Ваше рішення і відправили їх на перевірку. Очікуйте на результати 2-го листопада.</p>
    <br />
  </>
)

export const app_product_final_round_passed = (
  <>
    <p>Вітаємо, Ваша робота Онлайн Раунду стала однією з найкращих. Отже, ласкаво просимо до Фіналу, який відбудеться 28 – 29 листопада в онлайн форматі.</p>
    <br />
    <p>Звертаємо увагу: у Вас є можливість зареєструватися на командну номінацію. Її таймінг не пересікається з Фіналом. </p>
    <br />
    <p>
      Ви можете переглянути
      <a target='_blank' rel='noopener noreferrer' href='https://www.youtube.com/watch?v=dptIruZ2TBI&feature=youtu.be' >Online Meetup із суддями номінацій напрямку Дизайн.</a>
    </p>
  </>
)

export const app_product_final_round_failed = (
  <>
    <p>Невтішні новини сповіщати неприємно, але нам доведеться. На жаль, Ви не пройшли до Фіналу. Щиро дякуємо за участь у DEV Challenge XVIII. Бажаємо Вам не втрачати віру у власні сили, чекаємо у наступних сезонах. Впевнені: колись Ви увійдете в історію як переможець Чемпіонату.</p>
    <br />
    <p>Ми цінуємо час, який Ви витратили на виконання завдання, саме тому хочемо поділитися фідбеком щодо оцінювання. На жаль, не зможемо надати індивідуальний аналіз роботі, притому традиційно в рамках Чемпіонату ми організовуємо фідбек-сесію. 14 листопада, о 12:00, запрошуємо на онлайн-зустріч з суддями, на якій вони поділяться загальними враженнями від робіт і порадами на майбутнє. Кілька суддів підготують доповіді на теми, що вас, учасників, цікавлять. У формі реєстрації Ви можете вказати свої питання. </p>
    <br />
    <div style={{ textAlign: 'center' }}>
      {' '}
      <a target='_blank' rel='noopener noreferrer' href='https://devchallenge19.typeform.com/to/n24HNnIz' className='upw_button '>Реєстрація на мітап</a>
    </div>
  </>
)

export const app_product_final_round_tasks = (
  <>
    <p>Вітаємо, Фіналісте DEV Challenge XVIII.</p>
    <br />
    <p>Пам’ятайте: Ви маєте вісім годин на роботу. Вже о 18:30 ми закриваємо форму, надіслання результатів стане неможливим.</p>
    <br />
    <div style={{ textAlign: 'center' }}>
      {' '}
      <a target='_blank' rel='noopener noreferrer' href='https://docs.google.com/document/d/1FYuuMq4FbiIitoGoC-KuU-OIrSL44SpUW-H8BeLvOV0/edit?usp=sharing' className='upw_button '>Отримати Завдання</a>
    </div>
  </>
)

export const app_product_online_round_texts = {
  1: app_product_qualification_registered,
  2: app_product_qualification_registered,
  3: app_product_online_round_solution_received,
  4: app_product_online_round_solution_not_received,
  5: app_product_final_round_passed,
  6: app_product_final_round_failed,
}

export const app_product_final_round_texts = {
  1: app_product_qualification_registered,
  2: app_product_qualification_registered,
  3: app_product_online_round_solution_received,
  4: app_product_online_round_solution_not_received,
  5: app_product_final_round_tasks,
  6: app_product_final_round_failed,
}
