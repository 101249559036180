import { Component } from 'react'
import QaTable from './QaTable'
import FrontendTable from './FrontendTable'


class TableManager extends Component {
  render () {
    const data = {
      Frontend: <FrontendTable />,
      Qa      : <QaTable />,
    }
    const default_component = <QaTable />
    const item = data[this.props.item.name]

    return (
      item || default_component

    )
  }
}

export default TableManager
