import { Component } from 'react'


class FormEnterUpdate extends Component {
  constructor (props) {
    super(props)
    this.state = {
      form_button_text: 'Заповнити профіль',
    }
  }

  componentDidMount () {
    console.log(this.props.updated)
    if (this.props.updated) {
      this.setState({
        form_button_text: 'Оновити анкету учасника',
      })
    }
  }

  render () {
    return (
      <a
        href='form'
        className='upw_button '
      >
        {this.state.form_button_text}
      </a>
    )
  }
}

export default FormEnterUpdate
