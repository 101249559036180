import { Component } from 'react'


class BlockWithTabsWidget extends Component {
  collect_images () {
    const arr = []

    this.props.item.block_data.forEach((obj) => {
      arr.push(obj.image.file_image_svg)
    })

    return arr
  }

  collect_headers () {
    const arr = []

    this.props.item.block_data.forEach((obj) => {
      arr.push(obj.heading)
    })

    return arr
  }

  collect_texts () {
    const arr = []

    this.props.item.block_data.forEach((obj) => {
      arr.push(obj.text_content)
    })

    return arr
  }

  constructor (props) {
    super(props)
    this.state = {
      title : this.props.item.name,
      button: this.collect_headers(),
      image : this.collect_images(),
      active: 0,
      text  : this.collect_texts(),
      style : [],
    }
  }

  handleOnClick (index) {
    // console.log(this.state.buttons_active_not);
    // this.state.buttons_active_not[this.state.last_active] ="inactive";
    // this.state.buttons_active_not[index] = "active";

    const style = []

    for (let i = 0; i < this.state.text.length; i++) {
      style.push({ display: 'none' })
    }
    style[index] = { display: 'grid' }
    this.setState({
      style : style,
      active: index,
    })
  }

  componentWillMount () {
    const style = []

    for (let i = 0; i < this.state.text.length; i++) {
      style.push({ display: 'none' })
    }
    style[0] = { display: 'grid' }
    this.setState({
      style: style,

    })
    this.handleOnClick = this.handleOnClick.bind(this)
  }

  render () {
    return (
      <div
        className='block-with-tabs'
        style={this.props.item.full_width ? { width: '100%' } : null}
      >
        <div className='block-with-tabs-title'>
          {this.state.title}
        </div>
        <div className='block-with-tabs-menu'>
          <ul className='block_with_animation_click'>
            {this.state.button.map((button, index) => {
                          return (
                            <li style={{ width: `${100 / this.state.button.length}+"%"` }}>

                              <span
                                className={index === this.state.active ? 'button-white-span__cell active_nav_item' : 'button-white-span__cell'}
                              >
                                <span
                                  key={index}
                                  className='child-button-white'
                                  onClick={() => this.handleOnClick(index)}
                                >
                                  {button}
                                </span>
                              </span>
                            </li>
)
                        })}
          </ul>
        </div>
        {this.state.text.map((text, index) => (
          <div
            key={index} style={this.state.style[index]}
            className='block-with-tabs-content'
          >
            <div className='block-with-tabs-text'>

              <p
                dangerouslySetInnerHTML={{ __html: this.state.text[index] }}
              />
            </div>
            {this.state.image[index]
                          ? (
                            <div
                              className='block-with-tabs-img' style={{
                            textAlign: 'center',
                              }}
                            >
                              <img
                                className='block-wtih-tabs-main_icon' src={this.state.image[index]} style={{ filter: 'brightness(100)' }}
                                alt=''
                              />
                            </div>
)
                          : ''}
          </div>
                  ),
                )}
      </div>
    )
  }
}

export default BlockWithTabsWidget
