import classNames from 'classnames'
import { useRef } from 'react'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import { toast } from 'react-toastify'

import schema from 'components/forms/schema'

import styles from './SubscribeForm.module.css'
import { ReactComponent as LetterIcon } from 'assets/letter.svg'


export default function SubscribeForm () {
  const formRef = useRef<HTMLFormElement>(null)

  return (
    <Formik
      initialValues={{
        fields: {
          email: '',
        },
      }}
      validationSchema={(_: any) => {
        return yup.object().shape({
          fields: yup.object().shape({
            email: yup.reach(schema, 'email'),
          }),
        })
      }}
      onSubmit={(_, { setSubmitting, resetForm }) => {
        formRef.current?.submit()
        setSubmitting(false)
        resetForm()
        toast.success('Ви успішно підписалися на новини!')
      }}
    >
      {({ isSubmitting, values, errors }) => (
        <Form
          ref={formRef}
          className='subscribe_form'
          action='//app.mailerlite.com/webforms/submit/q1h1j9'
          data-id='361163'
          data-code='q1h1j9'
          method='POST'
          target='response-iframe'
        >
          <button type='submit' className='button_subscribe' disabled={isSubmitting}>
            <LetterIcon />
          </button>
          <input type='hidden' name='ml-submit' value='1' />
          <Field
            id='input_subscribe'
            className={classNames({
              subscribe_field     : true,
              upw_subscribe_active: values.fields.email,
              upw_subscribe_error : errors.fields?.email,
            })}
            type='email'
            name='fields[email]'
            placeholder='Введіть e-mail адресу'
            novalidate
          />
          <label className={`subscribe_text${(values.fields.email) ? ' upw_subscribe_label_active' : ''}`}>
            Новини на e-mail
          </label>
          <iframe name='response-iframe' className={styles.responseIframe} />
        </Form>
      )}
    </Formik>
  )
}
