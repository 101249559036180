import { Component } from 'react'


class BigImageWidget extends Component {
  constructor (props) {
    super(props)
    this.state = {
      img: this.props.item.image_svg.file_image_svg,
    }
  }

  render () {
    return (
      <div className='big-image'>
        <div className={'big-image__wrapper'}>
          <img src={this.state.img} alt='' />
        </div>
      </div>
    )
  }
}

export default BigImageWidget
