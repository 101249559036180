import { Component } from 'react'


class TextButtonWidget extends Component {
  render () {
    return (
      <div className='TextButtonWidget'>
        <div className='wrapperContentText'>
          <div
            className='titleButtonText'
          >
            {this.props.item.title}
          </div>
          <div className='contentMainButtonWidget'>
            <p dangerouslySetInnerHTML={{ __html: this.props.item.text }} />
            {this.props.item.button_title
                          ? (
                            <div style={{ textAlign: 'center' }}>
                              <a
                                href={this.props.item.button_href}
                                rel='noopener noreferrer'
                                target='_blank'
                                className='button_with_animation_slide'
                              >
                                {this.props.item.button_title}

                              </a>
                            </div>
)
                          : null}
          </div>
        </div>
      </div>
    )
  }
}

export default TextButtonWidget
