import { Component } from 'react'
import 'whatwg-fetch'
import CaseSwitcher from './CaseSwitcher'
import Cookies from 'universal-cookie'


const widget_names = ['big_image', 'block_with_tabs', 'banner', 'slider_move',
  'carts', 'story_of_greatness', 'slider_photo_video', 'mini_text',
  'time_line', 'modified_block_with_tabs', 'login', 'registration', 'devchallenge_seventeen_team_form',
  'nav_bar', 'footer', 'profile', 'form', 'table', 'text_button', 'connection', 'youtube', 'finals_table', 'custom_widget', 'design_form', 'devchallenge_seventeen_form']

class Post extends Component {
  constructor (props) {
    super(props)

    this.cookies = new Cookies()
    this.route = window.location.href.split('/')[3]
  }

    allwidgets = () => {
      const widg = []

      widget_names.forEach((widget_single) => {
        this.props.widgets[0][widget_single].forEach((PostItem, index) => {
          widg.push(PostItem)
        })
      })

      function compare (a, b) {
        const genreA = a.ordering
        const genreB = b.ordering

        let comparison = 0

        if (genreA > genreB) {
          comparison = 1
        } else if (genreA < genreB) {
          comparison = -1
        }

        return comparison
      }
      widg.sort(compare)

      return widg
    }

    render () {
      return (
        <div className='container-fluid'>
          <div className='fix_overflow'>
          </div>
          {this.props.widgets[0] !== undefined
                  ? (
                    <CaseSwitcher
                      current_page={this.route}
                      user_data={this.props.user_data}
                      item={this.allwidgets()}
                    />
)
                  : ''}
          <div className='fix_overflow_bottom'>
          </div>
        </div>
      )
    }
}

export default Post
