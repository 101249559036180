export const team_qualification_text = (
  <>
    <p>Вітаємо, Ви зареєструвалися у DEV Challenge XVIII. Номінація Team в цьому сезоні має один етап, він стартує 20 листопада та триватиме до 27 листопада. </p>
    <br />
    <p>
      Прогнозований час виконання на людину: 10+ годин.
    </p>
    <br />
    <p>
      Для зв‘язку з організаторами, суддями та іншими учасниками — додавайтеся до
      {' '}
      <a rel='noopener noreferrer' target='_blank' href='https://join.slack.com/t/devchallengehq/shared_invite/zt-iwumav43-_Ye9V9EPNMQr9tHhKKdv9g'> Slack Workspace</a>
      . Ознайомитися з Туторіалом учасника Ви можете за
      <a target='_blank' rel='noopener noreferrer' href='https://docs.google.com/document/d/1hJ1RgRWRBkV6f4975QywkHhgNzoanUaUi-xzYWTOQcE/edit' > посиланням</a>
      .
    </p>
    <br />
    <div style={{ textAlign: 'center' }}>
      {' '}
      <a target='_blank' rel='noopener noreferrer' href='https://docs.google.com/document/d/1JtG90qjDGbWIuKVkSsSYGLCQ0bRpsx3FXybPzq2SqzI/edit#heading=h.cyrz6xdobf' className='upw_button '>Отримати завдання</a>
    </div>
  </>
)

export const team_texts = {
  1: team_qualification_text,
  2: team_qualification_text,
  3: team_qualification_text,
  4: team_qualification_text,
}
