export const getCurrentStage = () => {
  const today = new Date()
  const year = today.getFullYear()
  const month = today.getMonth()
  const day = today.getDate()
  const hours = today.getHours()

  if (year > 2020) return true
  if (month > 11) return true
  if (day > 28) return true
  if (hours >= 10) return true

  return false
}

export const getCurrentStageForSubmition = () => {
  const today = new Date()
  const day = today.getDate()
  const hours = today.getHours()
  const minutes = today.getMinutes()

  if (day === 28) {
    if (hours >= 10 & hours <= 18) {
      if (hours === 18 & minutes > 30) {
        return false
      }

      return true
    }
  }

  return false
}
