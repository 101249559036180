import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import './styles/frame.css'
import './styles/BlockWithTabs.css'
import './styles/Banner.css'
import './styles/navbar.css'
import './styles/SliderPhotoViedoWidget.css'
import './styles/SliderMoveWidget.css'
import './styles/CartsWidget.css'
import './styles/login.css'
import './styles/common.css'
import './styles/SliderPhotoWidget.css'
import './styles/footer.css'
import './styles/HistoryOfSuccessWidget.css'
import './styles/TextWidget.css'
import './styles/FileSubmition.css'
import './styles/MiniTimelineWidget.css'
import './styles/BigImage.css'
import './styles/BlocWithTextWidget.css'
import './styles/RestoreWidget.css'
import './styles/TextButtonWidget.css'
import './styles/ProfileWidget.css'
import './styles/FormWidget.css'
import './styles/ChatWidget.css'
import './styles/MainProfile.css'
import './styles/FinalsTable.css'
import './styles/TableWidget.css'
import './styles/ConnectionWidget.css'
import './styles/TournamentsButtonPack.css'

import App from './App'
import { store } from './store_reducer'


ReactDOM.render(
  <Provider
    store={store}
  >
    <App />
  </Provider>,
  document.getElementById('root'),
)
