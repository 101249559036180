import { Component } from 'react'


const Heading = (heading) => {
  return (
    <div className='bwc-heading'>
      <h1>{heading}</h1>
    </div>
  )
}
const Card = (data, index, len) => {
  const handleClass = (e) => {
    if (e.target.parentNode.parentNode.className === 'bwc-card-inner') {
      for (let i = 0; i < e.target.parentNode.parentNode.parentNode.parentNode.childNodes.length; i++) {
        if (e.target.parentNode.parentNode.parentNode.parentNode.childNodes[i].firstChild.className === 'bwc-card-inner-active') {
          e.target.parentNode.parentNode.parentNode.parentNode.childNodes[i].firstChild.className = 'bwc-card-inner'
        }
      }
      e.target.parentNode.parentNode.className = 'bwc-card-inner-active'
      e.target.innerHTML = 'X'
    } else {
      e.target.parentNode.parentNode.className = 'bwc-card-inner'
      e.target.innerHTML = '[ + ]'
    }

    for (let i = 0; i < e.target.parentNode.parentNode.parentNode.parentNode.childNodes.length; i++) {
      if (e.target.parentNode.parentNode.parentNode.parentNode.childNodes[i].firstChild.className === 'bwc-card-inner') {
        if (e.target.parentNode.parentNode.parentNode.parentNode.childNodes[i].firstChild.childNodes[3]) {
          e.target.parentNode.parentNode.parentNode.parentNode.childNodes[i].firstChild.childNodes[3].childNodes[0].innerHTML = '[ + ]'
        }
      }
    }
  }

  const handleImage = (data) => {
    // For Multiple Images

    const multipleActive = (e) => {
      e.target.src = data.image[1]
    }

    const multipleInactive = (e) => {
      e.target.src = data.image[0]
    }

    // For single image

    const singleActive = (e) => {
      e.target.style.filter = ''
    }

    const singleInactive = (e) => {
      e.target.style.filter = 'brightness(0) invert(1)'
    }

    let img

    const hasURL = data.url && data.url !== '#'
    const URL = data.url

    if (data.image.length > 1) {
      img = (
        <img
          src={data.image[0]}
          alt=''
          className={!hasURL && 'bwc-card-inner-image'}
          onMouseEnter={multipleActive}
          onMouseLeave={multipleInactive}
        />
      )
    } else {
      img = (
        <img
          src={data.image[0]}
          style={{ filter: 'brightness(0) invert(1)' }}
          alt=''
          className={!hasURL && 'bwc-card-inner-image'}
          onMouseEnter={singleActive}
          onMouseLeave={singleInactive}
        />
      )
    }

    return hasURL
      ? (
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={URL}
          className='bwc-card-inner-image'
        >
          {img}
        </a>
      )
      : img
  }

  return (
    <div key={index} className='bwc-card' style={{ width: `${100 / len}%` }}>
      <div className='bwc-card-inner'>
        {/* img */}
        {handleImage(data)}
        {/* title */}
        <h1>{data.title}</h1>
        {/* description */}
        <h2>{data.description}</h2>
        {/* button */}
        {data.text !== ''
                  ? (
                    <div style={{ color: '#FFF' }} className='read-more'>
                      <span onClick={handleClass}>[ + ]</span>
                    </div>
)
                  : null}
        {data.text !== ''
                  ? (
                    <div className='content-active'>

                      <p dangerouslySetInnerHTML={{ __html: data.text }} align='left' />
                    </div>
)
                  : null}
      </div>
    </div>
  )
}

class BlockWithTextWidget extends Component {
  collect_data () {
    const data = []

    this.props.item.block_data.forEach((item, index) => {
      data.push(
        {
          image      : [item.image.file_image_svg],
          url        : item.url,
          title      : item.header,
          description: item.subtitle,
          text       : item.text,
        },
      )
    })

    return data
  }

  constructor (props) {
    super(props)
    this.state = {
      heading: this.props.item.title,
      data   : this.collect_data(),
    }
  }

  render () {
    return (
      <div className='bwc'>
        {this.state.heading !== '' ? Heading(this.state.heading) : null}
        {this.state.data.map((data, index) => {
                  return (
                    Card(data, index, this.state.data.length)
                  )
                })}
      </div>
    )
  }
}

export default BlockWithTextWidget
