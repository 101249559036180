import { Component } from 'react'
import 'styles/Preloader.css'
import { mediaURL } from 'global.js'


const getRandomArbitrary = (min, max) => {
  const num = Math.random() * (max - min) + min

  return Math.round(num)
}

class Preloader extends Component {
  render () {
    const gifs = ['/media/local/moto.gif', '/media/local/start.gif', '/media/local/flappy.gif']
    const src = gifs[getRandomArbitrary(0, 2)]

    return (
      <a href='https://www.facebook.com/devchallengeua'>
        <div className='loader'>
          <div className='logo'>
            <img alt='Loader' className='image lazyloaded' src={mediaURL + src}></img>
          </div>
        </div>
      </a>
    )
  }
}

export default Preloader
